<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <div class="breadcrumb-title pe-3">All Page Meta CMS</div>
                <div class="ps-3">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb mb-0 p-0">
                            <li class="breadcrumb-item">
                                <router-link to="/home"><i class="bx bx-home-alt"></i></router-link>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">All Page Meta CMS</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">All Page Meta CMS</h5>
                    <hr/>
                    <div class="form-body mt-4">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="border border-3 p-4 rounded">
                                    <div class="row mb-3">
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Signup Page Meta Keyword:</label>
                                                <textarea v-model="cmsData.signupPageMetaKeyword" class="form-control" placeholder="Signup page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Signup Page Meta Description:</label>
                                                <textarea v-model="cmsData.signupPageMetaDescription" class="form-control" placeholder="Signup page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Login Page Meta Keyword:</label>
                                                <textarea v-model="cmsData.loginPageMetaKeyword" class="form-control" placeholder="Login page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Login Page Meta Description:</label>
                                                <textarea v-model="cmsData.loginPageMetaDescription" class="form-control" placeholder="Login page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Home Page Meta Keyword:</label>
                                                <textarea v-model="cmsData.homePageMetaKeyword" class="form-control" placeholder="Home page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Home Page Meta Description:</label>
                                                <textarea v-model="cmsData.homePageMetaDescription" class="form-control" placeholder="Home page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">About Page Meta Keyword:</label>
                                                <textarea v-model="cmsData.aboutPageMetaKeyword" class="form-control" placeholder="About page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">About Page Meta Description:</label>
                                                <textarea v-model="cmsData.aboutPageMetaDescription" class="form-control" placeholder="About page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Pricing Page Meta Keyword:</label>
                                                <textarea v-model="cmsData.pricingPageMetaKeyword" class="form-control" placeholder="Pricing page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Pricing Page Meta Description:</label>
                                                <textarea v-model="cmsData.pricingPageMetaDescription" class="form-control" placeholder="Pricing page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Contact Page Meta Keyword:</label>
                                                <textarea v-model="cmsData.contactPageMetaKeyword" class="form-control" placeholder="Contact page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Contact Page Meta Description:</label>
                                                <textarea v-model="cmsData.contactPageMetaDescription" class="form-control" placeholder="Contact page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Career Page Meta Keyword:</label>
                                                <textarea v-model="cmsData.careerPageMetaKeyword" class="form-control" placeholder="Career page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Career Page Meta Description:</label>
                                                <textarea v-model="cmsData.careerPageMetaDescription" class="form-control" placeholder="Career page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Career Details Page Meta Keyword:</label>
                                                <textarea v-model="cmsData.careerDetailsPageMetaKeyword" class="form-control" placeholder="Career Details page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Career Details Page Meta Description:</label>
                                                <textarea v-model="cmsData.careerDetailsPageMetaDescription" class="form-control" placeholder="Career Details page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">FAQ Page Meta Keyword:</label>
                                                <textarea v-model="cmsData.faqPageMetaKeyword" class="form-control" placeholder="FAQ page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">FAQ Page Meta Description:</label>
                                                <textarea v-model="cmsData.faqPageMetaDescription" class="form-control" placeholder="FAQ page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">FAQ Details Page Meta Keyword:</label>
                                                <textarea v-model="cmsData.faqDetailsPageMetaKeyword" class="form-control" placeholder="FAQ Details page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">FAQ Details Page Meta Description:</label>
                                                <textarea v-model="cmsData.faqDetailsPageMetaDescription" class="form-control" placeholder="FAQ Details page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Partner Page Meta Keyword:</label>
                                                <textarea v-model="cmsData.partnerPageMetaKeyword" class="form-control" placeholder="Partner page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Partner Page Meta Description:</label>
                                                <textarea v-model="cmsData.partnerPageMetaDescription" class="form-control" placeholder="Partner page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Press Kit Page Meta Keyword:</label>
                                                <textarea v-model="cmsData.pressKitPageMetaKeyword" class="form-control" placeholder="Press Kit page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Press Kit Page Meta Description:</label>
                                                <textarea v-model="cmsData.pressKitPageMetaDescription" class="form-control" placeholder="Press Kit page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Terms Page Meta Keyword:</label>
                                                <textarea v-model="cmsData.termsPageMetaKeyword" class="form-control" placeholder="Terms page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Terms Page Meta Description:</label>
                                                <textarea v-model="cmsData.termsPageMetaDescription" class="form-control" placeholder="Terms page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Security Page Meta Keyword:</label>
                                                <textarea v-model="cmsData.securityPageMetaKeyword" class="form-control" placeholder="Security page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Security Page Meta Description:</label>
                                                <textarea v-model="cmsData.securityPageMetaDescription" class="form-control" placeholder="Security page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Privacy Page Meta Keyword:</label>
                                                <textarea v-model="cmsData.privacyPageMetaKeyword" class="form-control" placeholder="Privacy page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Privacy Page Meta Description:</label>
                                                <textarea v-model="cmsData.privacyPageMetaDescription" class="form-control" placeholder="Privacy page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Schedule Page Meta Keyword:</label>
                                                <textarea v-model="cmsData.schedulePageMetaKeyword" class="form-control" placeholder="Schedule page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Schedule Page Meta Description:</label>
                                                <textarea v-model="cmsData.schedulePageMetaDescription" class="form-control" placeholder="Schedule page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-grid">
                                        <button v-on:click="updateCms()" type="button" class="btn btn-primary">Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="../js/site-page-meta-cms.js"></script>