import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
export default {
    data() {
        return {
            url: this.$serverURL + this.$api.home.whatsNextSectionCms,
            cmsData: {},
            preview: {
                image: "",
                mobileImage: "",
            },
            editor: ClassicEditor,
            editorConfig: {
                ckfinder: {
                    uploadUrl: this.$serverURL + this.$api.editor.uploadFileURL
                }
            },
        }
    },
    async created() {
        document.title = "Transaction Bee - Career Page CMS";
        this.getData();

    },
    methods: {
        async getData() {
            let response = await this.getCmsData(this.url);
            if (response.statusCode == 200) {
                this.cmsData = (response.data.length > 0) ? response.data[0] : {};
            }
            else {
                this.cmsData = {};
            }
        },
        handleUploadImage(imageKey, previewImageKey, ref, event) {
            this.cmsData[imageKey] = event.target.files[0];
            let input = this.$refs[ref];
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.preview[previewImageKey] = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        async updateCms() {
            try {
                const formData = new FormData();
                formData.append('sectionOnetitle', this.cmsData.sectionOnetitle);
                formData.append('sectionOneSubtitle', this.cmsData.sectionOneSubtitle);
                formData.append('sectionOneDescription', this.cmsData.sectionOneDescription);
                formData.append('sectionOneButtonText', this.cmsData.sectionOneButtonText);
                formData.append('sectionOneButtonLink', this.cmsData.sectionOneButtonLink);
                formData.append('sectionTwotitle', this.cmsData.sectionTwotitle);
                formData.append('sectionTwoSubtitle', this.cmsData.sectionTwoSubtitle);
                formData.append('sectionTwoDescription', this.cmsData.sectionTwoDescription);
                formData.append('sectionTwoButtonText', this.cmsData.sectionTwoButtonText);
                formData.append('sectionTwoButtonLink', this.cmsData.sectionTwoButtonLink);
                formData.append('sectionOneImage', this.cmsData.sectionOneImage);
                formData.append('sectionTwoImage', this.cmsData.sectionTwoImage);
                this.$swal.showLoading();
                let response = await this.createUpdateCMS(this.url, formData);
                if (response.statusCode == 200 || response.statusCode == 201) {
                    this.$refs.sectionOneImage.value = "";
                    this.$refs.sectionTwoImage.value = "";
                    this.preview = {
                        image: "",
                        mobileImage: "",
                    }
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
                await this.getData();
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            } finally {
                this.$swal.hideLoading();
            }
        }
    }
}