import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
    data() {
        return {
            requestUrl: this.$serverURL + this.$api.content.accelerateContent,
            dataList: [],
            formData: {
                description: ""
            },
            singleData: {
                description: "",

            },
            imageObj: {
                image: "",
                updateImage: ""
            },
            updateImage: "",
            preview: {
                imageOne: "",
                imageTwo: "",
            },
            editor: ClassicEditor,
            editorConfig: {
                ckfinder: {
                    uploadUrl: this.$serverURL + this.$api.editor.uploadFileURL
                }
            },
        }
    },
    async created() {
        document.title = "Transaction Bee - Accelerate Content";
        await this.getData();
        this.loadScripts();//from mixin
    },
    methods: {
        async getData() {
            await this.getDataList(this.requestUrl).then(response => {
                if (response.statusCode == 200) {
                    this.dataList = response.data;
                }
            });
        },

        handleUploadImage(varName, previewVar, ref, event) {
            this.imageObj[varName] = event.target.files[0];
            let input = this.$refs[ref];
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.preview[previewVar] = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },


        checkValidation(data) {
            if (!data.description) {
                this.showToaster('Please enter description.', 'info');
                return false;
            } else if (!this.imageObj.image && !this.imageObj.updateImage) {
                this.showToaster('Please select an image.', 'info');
                return false;
            }
            return true;
        },

        async createContent() {
            try {
                if (this.checkValidation(this.formData)) {
                    const formData = new FormData();
                    formData.append('description', this.formData.description);
                    formData.append('image', this.imageObj.image);
                    this.$swal.showLoading();
                    await this.postData(this.requestUrl, formData).then(async (response) => {
                        if (response.statusCode == 201) {
                            this.formData = {
                                description: "",
                            };
                            this.imageObj.image = "";
                            this.$refs.createImage.value = "";
                            this.preview.imageOne = ""
                            this.showToaster(response.message, 'success');
                            await this.getData();
                            document.getElementById('add-close-btn').click();
                        }
                    });
                }
            } catch (error) {
                console.log(error);
            }
        },
        editData(data) {
            this.singleData = data;
            this.imageObj.updateImage = data.image;
        },
        update: async function () {
            if (this.checkValidation(this.singleData)) {
                const formData = new FormData();
                formData.append('description', this.singleData.description);
                formData.append('image', this.imageObj.updateImage);
                let url = this.requestUrl + '/' + this.singleData.id;
                this.$swal.showLoading();
                await this.updateData(url, formData).then(async (response) => {
                    if (response.statusCode == 200) {
                        this.singleData = {
                            description: "",
                        };
                        this.showToaster(response.message, "success");
                        this.imageObj.updateImage = "";
                        this.$refs.editImage.value = "";
                        this.preview.imageTwo = ""
                        await this.getData();
                        document.getElementById('edit-close-btn').click();
                    }
                });
            }
        },

        async deleteData(id) {
            const url = this.requestUrl + '/' + id
            await this.deleteContent(url).then(async (response) => {
                if (response == true) {
                    await this.getData();
                }
            })
        }
    }
}