<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <div class="breadcrumb-title pe-3">Signup CMS</div>
                <div class="ps-3">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb mb-0 p-0">
                            <li class="breadcrumb-item">
                                <router-link to="/dashboard">
                                    <i class="bx bx-home-alt"></i>
                                </router-link>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">Signup CMS</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <form @submit.prevent="updateCms()">
                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title">Signup CMS</h5>
                        <hr />
                        <div class="form-body mt-4">
                            <div class="row">
                                <div class="col-lg-8 mb-3">
                                    <div class="border border-3 p-4 rounded">
                                        <div class="row">
                                            <div class="col-12 mb-3">
                                                <label class="form-label">Site Title:</label>
                                                <input type="text" v-model="cmsData.siteTitle" class="form-control"
                                                    id="inputPageHeaderTitle" placeholder="Enter site title" />
                                            </div>
                                            <div class="col-12 mb-3">
                                                <label class="form-label">Page Title:</label>
                                                <input type="text" v-model="cmsData.pageTitle" class="form-control"
                                                    id="inputPageHeaderTitle" placeholder="Enter page title" />
                                            </div>
                                            <div class="col-12 mb-3">
                                                <label class="form-label">Page Subtitle:</label>
                                                <input type="text" v-model="cmsData.pageSubTitle" class="form-control"
                                                    id="inputPageHeaderTitle" placeholder="Enter page subtitle" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-8 mb-3">
                                    <div class="border border-3 p-4 rounded">
                                        <div class="row">
                                            <div class="col-12 mb-3">
                                                <label class="form-label">
                                                    Logo: [Preferred Image Size: 311X40, Max Upload Limit:
                                                    1MB]
                                                </label>
                                                <input type="file" class="form-control" id="inputPageHeaderTitle"
                                                    placeholder="Enter page title" ref="logo" accept="image/*"
                                                    @change="handleUploadImage('logo', 'logo', 'logo', $event)" />
                                            </div>
                                            <div class="mb-3">
                                                <img style="position: relative;" v-if="preview.logo" :src="preview.logo"
                                                    height="50" />
                                                <img style="position: relative;" v-else-if="cmsData.logo"
                                                    :src="cmsData.logo" height="50" />
                                            </div>
                                            <div class="col-12 mb-3">
                                                <label class="form-label">Button Text:</label>
                                                <input type="text" v-model="cmsData.buttonText" class="form-control"
                                                    id="inputPageHeaderTitle" placeholder="Enter sign button text" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-grid col-12 col-lg-8">
                                    <button type="submit" class="btn btn-primary">Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>
  
<script src="../js/sign-up-cms.js"></script>