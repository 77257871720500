<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="row row-cols-1 row-cols-lg-2 row-cols-xl-3">
                <div class="col">
                    <div class="card radius-10 cp" @click="$router.push('/subscription')">
                        <div class="card-body">
                            <div class="d-flex align-items-center">
                                <div>
                                    <p class="mb-0 text-secondary">Total Subscription</p>
                                    <h4 class="my-1">{{ totalSubscriber }}</h4>
                                </div>
                                <div class="widgets-icons bg-light-primary text-primary ms-auto">
                                    <i class="fa-solid fa-store"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card radius-10 cp" @click="$router.push('/contact')">
                        <div class="card-body">
                            <div class="d-flex align-items-center">
                                <div>
                                    <p class="mb-0 text-secondary">Total Contact</p>
                                    <h4 class="my-1">{{ totalContact }}</h4>
                                </div>
                                <div class="widgets-icons bg-light-danger text-danger ms-auto">
                                    <i class="fa-solid fa-message"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card radius-10 cp" @click="$router.push('/career-applicant')">
                        <div class="card-body">
                            <div class="d-flex align-items-center">
                                <div>
                                    <p class="mb-0 text-secondary">Total Career Applicant</p>
                                    <h4 class="my-1">{{ totalCareerApplicant }}</h4>
                                </div>
                                <div class="widgets-icons bg-light-danger text-danger ms-auto">
                                    <i class="fa-solid fa-briefcase"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-12 d-flex">
                    <div class="card radius-10 w-100 mt-4">
                        <div class="card-body">
                            <div class="d-flex justify-content-around align-items-center">
                                <div class="btn-group m-2">
                                    <button type="button" @click="$router.push('/press-link')"  class="btn  btn-primary rounded-circle m-1" style="height: 120px; width:120px;">
                                        Press
                                    </button>
                                </div>
                                <div class="btn-group m-2">
                                    <button type="button" @click="$router.push('/accelerate-content')"  class="btn  btn-primary rounded-circle m-1" style="height: 120px; width:120px;">
                                        Partner
                                    </button>
                                </div>
                                <div class="btn-group m-2">
                                    <button type="button" @click="$router.push('/home-page-hero-section-cms')"  class="btn  btn-primary rounded-circle m-1" style="height: 120px; width:120px;">
                                        Home Page CMS
                                    </button>
                                </div>
                                <div class="btn-group m-2">
                                    <button type="button" @click="$router.push('/about-page-content')"  class="btn  btn-primary rounded-circle m-1" style="height: 120px; width:120px;" >
                                        About
                                    </button>
                                </div>
                                <div class="btn-group m-2">
                                    <button type="button" @click="$router.push('/office-address')"  class="btn  btn-primary rounded-circle m-1" style="height: 120px; width:120px;">
                                        Office Address
                                    </button>
                                </div>
                                <div class="btn-group m-2">
                                    <button type="button" @click="$router.push('/how-it-works-crud')"  class="btn  btn-primary rounded-circle m-1" style="height: 120px; width:120px;" >
                                        How it Works CMS
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="../js/dashboard.js"></script>

<style scoped>
.edit-button {
    background-color: #17a00e;
}

.delete-button {
    background-color: #f41127;
}
.cp {
    cursor: pointer;
}
</style>