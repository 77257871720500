// import { authHeader } from '../../../auth';

export default {
    name: "Category",
    data() {
        return {
            requestUrl: this.$serverURL + this.$api.content.pressCoverage,
            dataList: [],
            formData: {
                title: "",
                description: "",
            },
            singleData: {
                title: "",
                description: "",
            },
            image: "",
            previewImage: "",
        }
    },
    async created() {
        document.title = "Transaction Bee - Press Link Content";
        await this.getData();
        this.loadScripts();
    },
    methods: {
        async getData() {
            await this.getDataList(this.requestUrl).then(response => {
                if (response.statusCode == 200) {
                    this.dataList = response.data;
                }
            });
        },

        handleUploadImage(ref, event) {
            this.image = event.target.files[0];
            let input = this.$refs[ref];
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewImage = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },

        checkValidation(data) {
            if (!data.title) {
                this.showToaster('Please enter title.', 'info')
                return false;
            } else if (!data.description) {
                this.showToaster('Please enter description.', 'info')
                return false;
            } else if (!this.image) {
                this.showToaster('Please select an image.', 'info')
                return false;
            }
            return true;
        },

        async createContent() {
            try {
                if (this.checkValidation(this.formData)) {
                    const formData = new FormData();
                    formData.append('title', this.formData.title);
                    formData.append('description', this.formData.description);
                    formData.append('image', this.image);
                    this.$swal.showLoading();
                    await this.postData(this.requestUrl, formData).then(async (response) => {
                        if (response.statusCode == 201) {
                            this.formData = {
                                title: "",
                                description: "",
                            };
                            this.image = "";
                            this.previewImage = "";
                            this.$refs.createImage.value = "";
                            this.showToaster(response.message, 'success');
                            await this.getData();
                            document.getElementById('add-close-btn').click();
                        }
                    });
                }
            } catch (error) {
                console.log(error);
            }
        },
        editData(data) {
            this.image = "";
            this.previewImage = "";
            this.singleData = data;
            this.image = data.image;
        },
        update: async function () {
            if (this.checkValidation(this.singleData)) {
                const formData = new FormData();
                formData.append('title', this.singleData.title);
                formData.append('description', this.singleData.description);
                formData.append('image', this.image);
                let url = this.requestUrl + '/' + this.singleData.id;
                this.$swal.showLoading();
                await this.updateData(url, formData).then(async (response) => {
                    if (response.statusCode == 200) {
                        this.$swal.close();
                        this.singleData = {};
                        this.image = "";
                        this.previewImage = "";
                        this.$refs.editImage.value = "";
                        await this.getData();
                        document.getElementById('edit-close-btn').click();
                    }
                });
            }
        },

        onCloseModal(ref) {
            this.image = "";
            this.previewImage = "";
            this.$refs[ref].value = "";
        },

        async deleteData(id) {
            const url = this.requestUrl + '/' + id
            await this.deleteContent(url).then(async (response) => {
                if (response == true) {
                    await this.getData();
                }
            })
        }
    }
}