export default {
    name: "SocialLinks",
    data() {
        return {
            singleSocialLink: {},
            title: "",
            icon: "",
            link: "",
            setURL: this.$serverURL + this.$api.cms.socialLinkURL,
            dataList: []
        }
    },
    async created() {
        document.title = "Transaction Bee - Social Links";
        await this.getAll();
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
        let style = document.createElement('link');
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = '/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css';
        document.head.appendChild(style);
    },
    methods: {
        async getAll() {
            try {
                const { data } = await this.getDataList(this.setURL);
                this.dataList = data;
            } catch (err) {
                console.log(err);
            }
        },
        addSocialLink: async function () {
            if (!this.title) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter title!"
                });
            }
            else if (!this.icon) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter icon class name!"
                });
            }
            else if (!this.link) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter link!"
                });
            }
            else {
                let data = {
                    title: this.title,
                    icon: this.icon,
                    link: this.link
                };
                let response = await this.postData(this.setURL, data);
                if (response.statusCode == 201) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
                this.title = '';
                this.icon = '';
                this.link = '';
                await this.getAll();
                document.getElementById("closeButton").click();
            }
        },
        editSocialLink: async function (data) {
            this.singleSocialLink = data;
        },
        updateSocialLink: async function () {
            if (!this.singleSocialLink.title) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter title!"
                });
            } else if (!this.singleSocialLink.icon) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter icon class name!"
                });
            }
            else if (!this.singleSocialLink.link) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter link!"
                });
            }
            else {
                let data = {
                    title: this.singleSocialLink.title,
                    icon: this.singleSocialLink.icon,
                    link: this.singleSocialLink.link
                };
                const url = this.setURL + '/' + this.singleSocialLink.id;
                let response = await this.updateData(url, data);
                if (response.statusCode == 200) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
                this.singleSocialLink = {};
                await this.getAll();
                document.getElementById("buttonClose").click();
            }
        },
        deleteSocialLink: async function (id) {
            try {
                const url = this.setURL + '/' + id;
                await this.deleteContent(url);
                await this.getAll();
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            }

        }
    }
}
