<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <div class="breadcrumb-title pe-3">Press Kit Page CMS</div>
                <div class="ps-3">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb mb-0 p-0">
                            <li class="breadcrumb-item">
                                <router-link to="/dashboard">
                                    <i class="bx bx-home-alt"></i>
                                </router-link>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">Press Kit Page CMS</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <form @submit.prevent="updateCms()">
                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title">Press Kit Page CMS</h5>
                        <hr />
                        <div class="form-body mt-4">
                            <div class="row">
                                <div class="col-lg-8 mb-3">
                                    <div class="border border-3 p-4 rounded">
                                        <div class="row">
                                            <!-- <div class="col-12 mb-3">
                                                <label class="form-label">Page Title:</label>
                                                <input type="text" v-model="cmsData.aboutIcon" class="form-control" placeholder="ex: fa-regular fa-house" />
                                            </div> -->
                                            <!-- <div class="col-12 mb-3">
                                                <label class="form-label">Site link</label>
                                                <input type="text" v-model="cmsData.aboutIcon" class="form-control" placeholder="ex: fa-regular fa-house" />
                                            </div> -->
                                            <div class="col-12 mb-3">
                                                <label class="form-label">About Icon class Name:</label>
                                                <input type="text" v-model="cmsData.aboutIcon" class="form-control" placeholder="ex: fa-regular fa-house" />
                                            </div>
                                            <div class="col-12 mb-3">
                                                <label class="form-label">Link Icon Class Name:</label>
                                                <input type="text" v-model="cmsData.linkIcon" class="form-control" placeholder="ex: fa-regular fa-house" />
                                            </div>
                                            <div class="col-12 mb-3">
                                                <label class="form-label">Press Coverage Icon Class Name:</label>
                                                <input type="text" v-model="cmsData.pressCoverageIcon" class="form-control" placeholder="ex: fa-regular fa-house" />
                                            </div>
                                            <div class="col-12 mb-3">
                                                <label class="form-label">Press Coverage Title:</label>
                                                <input type="text" v-model="cmsData.pressCoverageTitle" class="form-control" placeholder="Enter press coverage title" />
                                            </div>
                                        </div>
                                        <p>Note: Get Icons from <a href="https://fontawesome.com/icons" target="_blank">Fontawesome</a></p>
                                    </div>
                                </div>
                                <div class="d-grid col-12 col-lg-8">
                                    <button type="submit" class="btn btn-primary">Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script src="../js/press-kit-cms.js"></script>