export default {
    name: "Benefits",
    data() {
        return {
            single: {
                id: "",
                icon: "",
                title: "",
                description: "",
            },
            preview: {
                icon: "",
            },
            icon: "",
            title: "",
            description: "",
            setURL: this.$serverURL + this.$api.home.howItWorksSection,
            dataList: [],
        }
    },
    async created() {
        document.title = "Transaction Bee - Home Page How It Works";
        const { data } = await this.getDataList(this.setURL);
        this.dataList = data;
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
        let style = document.createElement('link');
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = '/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css';
        document.head.appendChild(style);
    },
    methods: {
        addBenefit: async function () {
            if (!this.icon) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please upload icon!"
                });
            } else if (!this.title) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please add title!"
                });
            } else if (!this.description) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please add description!"
                });
            } else {
                const fromData = new FormData();
                fromData.append("icon", this.icon);
                fromData.append("title", this.title);
                fromData.append("description", this.description);
                const url = this.setURL;
                let response = await this.postData(url, fromData);
                if (response.statusCode == 201) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
                this.title = '';
                this.description = '';
                this.icon = null;
                this.preview = {};
                this.$refs.imageAdd = null;
                const { data } = await this.getDataList(this.setURL);
                this.dataList = data;
                document.getElementById("closeButtonAdd").click();
            }
        },
        editBenefit: async function (data) {
            this.single = { ...data };
        },
        updateBenefit: async function () {
            if (!this.single.icon) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please upload icon!"
                });
            } else if (!this.single.title) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please add title!"
                });
            } else if (!this.single.description) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please add description!"
                });
            } else {
                const fromData = new FormData();
                fromData.append("icon", this.single.icon);
                fromData.append("title", this.single.title);
                fromData.append("description", this.single.description);
                const url = this.setURL + '/' + this.single.id;
                let response = await this.updateData(url, fromData);
                if (response.statusCode == 200) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
                this.single = {};
                this.$refs.imageEdit = null;
                this.preview = {};
                const { data } = await this.getDataList(this.setURL);
                this.dataList = data;
                document.getElementById("closeButtonEdit").click();
            }
        },
        deleteBrand: async function (id) {
            try {
                const url = this.setURL + '/' + id;
                await this.deleteContent(url);
                const { data } = await this.getDataList(this.setURL);
                this.dataList = data;
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            }
        },
        handleUploadImage(imageKey, previewImageKey, ref, event) {
            if (ref === "imageEdit") {
                this.single[imageKey] = event.target.files[0];
            } else {
                this.icon = event.target.files[0];
            }
            let input = this.$refs[ref];
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader;
                reader.onload = e => {
                    this.preview[previewImageKey] = e.target.result;
                }
                reader.readAsDataURL(file[0]);
            }
        },
    }
}