export default {
    name: "TransactionBeeHomePageEasyPayment",
    data() {
        return {
            requestUrl: this.$serverURL + this.$api.home.easyPaymentPackage,
            dataList: [],
            formData: {
                price: "",
                packageType: "",
                packageName: "",
                description: "",
                features: "",
                buttonText: "",
                buttonLink: "",
            },
            singleData: {
                price: "",
                packageType: "",
                packageName: "",
                description: "",
                features: "",
                buttonText: "",
                buttonLink: "",
            },
        }
    },
    async created() {
        document.title = "Transaction Bee - Home Page Easy Payment";
        await this.getData();
        this.loadScripts();
    },
    methods: {
        async getData() {
            await this.getDataList(this.requestUrl).then(response => {
                if (response.statusCode == 200) {
                    this.dataList = response.data;
                }
            });
        },

        checkValidation(data) {
            if (!data.price) {
                this.showToaster('Please enter price!')
                return false;
            } 
            if (!data.packageType) {
                this.showToaster('Please select package type!')
                return false;
            }
            if (!data.packageName) {
                this.showToaster('Please enter package name!')
                return false;
            }
            if (!data.description) {
                this.showToaster('Please enter description!')
                return false;
            }
            if (!data.features) {
                this.showToaster('Please enter features!')
                return false;
            }
            if (!data.buttonText) {
                this.showToaster('Please enter button text!')
                return false;
            }
            if (!data.buttonLink) {
                this.showToaster('Please enter button link!')
                return false;
            }
            return true;
        },

        async createContent() {
            try {
                if (this.checkValidation(this.formData)) {
                    const data = {
                        price: this.formData.price,
                        packageType: this.formData.packageType,
                        packageName: this.formData.packageName,
                        description: this.formData.description,
                        features: this.formData.features,
                        buttonText: this.formData.buttonText,
                        buttonLink: this.formData.buttonLink

                    }
                    this.$swal.showLoading();
                    await this.postData(this.requestUrl, data).then(async (response) => {
                        if (response.statusCode == 201) {
                            this.formData = {
                                price: "",
                                packageType: "",
                                packageName: "",
                                description: "",
                                features: "",
                                buttonText: "",
                                buttonLink: "",
                            };
                            this.showToaster(response.message, 'success');
                            await this.getData();
                            document.getElementById('add-close-btn').click();
                        }
                    });
                }
            } catch (error) {
                console.log(error);
            }
        },
        editData(data) {
            this.singleData = { ...data };
        },
        update: async function () {
            if (this.checkValidation(this.singleData)) {
                const data = {
                    price: this.singleData.price,
                    packageType: this.singleData.packageType,
                    packageName: this.singleData.packageName,
                    description: this.singleData.description,
                    features: this.singleData.features,
                    buttonText: this.singleData.buttonText,
                    buttonLink: this.singleData.buttonLink
                }
                let url = this.requestUrl + '/' + this.singleData.id;
                this.$swal.showLoading();
                await this.updateData(url, data).then(async (response) => {
                    if (response.statusCode == 200) {
                        this.showToaster(response.message, 'success');
                        this.singleData = {};
                        await this.getData();
                        document.getElementById('edit-close-btn').click();
                    }
                });
            }
        },

        async deleteData(id) {
            const url = this.requestUrl + '/' + id
            await this.deleteContent(url).then(async (response) => {
                if (response == true) {
                    await this.getData();
                }
            })
        }
    }
}