export default {
    name: "Benefits",
    data() {
        return {
            single: {
                id: "",
                name: "",
                link: "",
                logo: "",
            },
            preview: {
                image: "",
            },
            name: "",
            link: "",
            logo: "",
            setURL: this.$serverURL + this.$api.home.applicationIntegration,
            dataList: [],
        }
    },
    async created() {
        document.title = "Transaction Bee - Home Page Apllications Integration";
        const { data } = await this.getDataList(this.setURL);
        this.dataList = data;
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
        let style = document.createElement('link');
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = '/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css';
        document.head.appendChild(style);
    },
    methods: {
        addApplication: async function () {
            if (!this.name) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter name!"
                });
            } else if (!this.link) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please add link!"
                });
            } else if (!this.logo) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please upload logo!"
                });
            }  else {
                const fromData = new FormData();
                fromData.append("name", this.name);
                fromData.append("link", this.link);
                fromData.append("logo", this.logo);
                const url = this.setURL;
                let response = await this.postData(url, fromData);
                if (response.statusCode == 201) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
                this.name = '';
                this.link = '';
                this.logo = null;
                this.preview = {};
                this.$refs.imageAdd = null;
                const { data } = await this.getDataList(this.setURL);
                this.dataList = data;
                document.getElementById("closeButtonAdd").click();
            }
        },
        editApplication: async function (data) {
            this.single = { ...data };
        },
        updateApplication: async function () {
            if (!this.single.name) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter name!"
                });
            } else if (!this.single.link) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please add link!"
                });
            } else if (!this.single.logo) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please upload logo!"
                });
            } else {
                const fromData = new FormData();
                fromData.append("name", this.single.name);
                fromData.append("link", this.single.link);
                fromData.append("logo", this.single.logo);
                const url = this.setURL + '/' + this.single.id;
                let response = await this.updateData(url, fromData);
                if (response.statusCode == 200) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
                this.single = {};
                this.$refs.imageEdit = null;
                this.preview = {};
                const { data } = await this.getDataList(this.setURL);
                this.dataList = data;
                document.getElementById("closeButtonEdit").click();
            }
        },
        deleteApplication: async function (id) {
            try {
                const url = this.setURL + '/' + id;
                await this.deleteContent(url);
                const { data } = await this.getDataList(this.setURL);
                this.dataList = data;
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            }
        },
        handleUploadImage(imageKey, previewImageKey, ref, event) {
            if (ref === "imageEdit") {
                this.single[imageKey] = event.target.files[0];
            } else {
                this.logo = event.target.files[0];
            }
            let input = this.$refs[ref];
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader;
                reader.onload = e => {
                    this.preview[previewImageKey] = e.target.result;
                }
                reader.readAsDataURL(file[0]);
            }
        },
    }
}