// export default {
//     data() {
//         return {
//             url: this.$serverURL + this.$api.cms.signinCMS,
//             cmsData: {
//                 siteTitle: "",
//                 pageTitle: "",
//                 pageSubTitle: "",

//             },
//         }
//     },
//     async created() {
//         document.title = "Transaction Bee - Sign In CMS";
//         this.getData();
//     },
//     methods: {
//         async getData() {
//             let response = await this.getCmsData(this.url);
//             if (response.statusCode == 200) {
//                 this.cmsData = (response.data.length > 0) ? response.data[0] : {};
//             }
//             else {
//                 this.cmsData = {
//                     siteTitle: "",
//                     pageTitle: "",
//                     pageSubTitle: "",
//                 };
//             }
//         },

//         async updateCms() {
//             try {
//                 const data = {
//                     siteTitle: this.cmsData.siteTitle,
//                     pageTitle: this.cmsData.pageTitle,
//                     pageSubTitle: this.cmsData.pageSubTitle
//                 }
//                 this.$swal.showLoading();
//                 let response = await this.createUpdateCMS(this.url, data);
//                 if (response.statusCode == 200 || response.statusCode == 201) {
//                     this.$swal.fire({
//                         toast: true,
//                         position: 'top-end',
//                         text: response.message,
//                         icon: "success",
//                         showConfirmButton: false,
//                         timer: 3000,
//                         animation: false,
//                         timerProgressBar: true,
//                         didOpen: (toast) => {
//                             toast.addEventListener('mouseenter', this.$swal.stopTimer)
//                             toast.addEventListener('mouseleave', this.$swal.resumeTimer)
//                         }
//                     });
//                 }
//                 await this.getData();
//             } catch (error) {
//                 this.$swal.fire({
//                     icon: "error",
//                     text: "Something went wrong. Please try again!"
//                 });
//             } finally {
//                 this.$swal.hideLoading();
//             }
//         }
//     }
// }



export default {
    data() {
        return {
            url: this.$serverURL + this.$api.cms.signinCMS,
            cmsData: {},
            preview: {
                logo: "",
                googleButtonIcon: "",
                facebookButtonIcon: "",
            },
        }
    },
    async created() {
        document.title = "Transaction Bee - SignIn Page CMS";
        this.getData();

    },
    methods: {
        async getData() {
            let response = await this.getCmsData(this.url);
            if (response.statusCode == 200) {
                this.cmsData = (response.data.length > 0) ? response.data[0] : {};
            }
            else {
                this.cmsData = {};
            }
        },
        handleUploadImage(imageKey, previewImageKey, ref, event) {
            this.cmsData[imageKey] = event.target.files[0];
            let input = this.$refs[ref];
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.preview[previewImageKey] = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        async updateCms() {
            try {
                const formData = new FormData();
                formData.append('siteTitle', this.cmsData.siteTitle);
                formData.append('pageTitle', this.cmsData.pageTitle);
                formData.append('pageSubTitle', this.cmsData.pageSubTitle);
                formData.append('signInButtonText', this.cmsData.signInButtonText);
                formData.append('logo', this.cmsData.logo);
                formData.append('googleButtonIcon', this.cmsData.googleButtonIcon);
                formData.append('facebookButtonIcon', this.cmsData.facebookButtonIcon);
                this.$swal.showLoading();
                let response = await this.createUpdateCMS(this.url, formData);
                if (response.statusCode == 200 || response.statusCode == 201) {
                    this.$refs.logo.value = "";
                    this.$refs.googleButtonIcon.value = "";
                    this.$refs.facebookButtonIcon.value = "";
                    this.preview = {
                        logo: "",
                        googleButtonIcon: "",
                        facebookButtonIcon: "",
                    }
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
                await this.getData();
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            } finally {
                this.$swal.hideLoading();
            }
        }
    }
}