export default {
    name: "Disabled Users",
    data() {
        return {
            setURL: this.$serverURL + this.$api.user.userList,
            dataList: [],
        }
    },
    async created() {
        document.title = "Transaction Bee - Rejected User";
        await this.getUserList();
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
        let style = document.createElement('link');
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = '/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css';
        document.head.appendChild(style);
    },
    methods: {
        async getUserList(){
            const data = await this.getDataList(this.setURL);
            this.dataList = data.data.filter((el) => (el.status == "Disabled"));
        },

        deleteUser: async function (id) {
            let url = this.$serverURL + this.$api.user.singleUserURL + id;
            this.$swal.showLoading();
            await this.deleteContent(url).then(async (response) => {
                if (response == true) {
                    await this.getUserList();
                }
            })
        }
    }
}