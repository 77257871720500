export default {
    name: "AddNewPackage",
    data() {
        return {
            specificationKey: [],
            specificationValue: [],
            specificationArray: {},
            counter: 1,
            cmsData: {},
            price: "",
            packageType: "",
            packageName: "",
            description: "",
            buttonText: "",
            buttonLink: "",
        }
    },
    async created() {
        document.title = "Transaction Bee - Add New Package";
        // this.getData();
    },
    methods: {
        // async getData() {
        //     let url = this.$serverURL + this.$api.cms.headerBannerCmsURL;
        //     let response = await this.getCmsData(url);
        //     if (response.statusCode == 200) {
        //         this.cmsData = (response.data.length > 0) ? response.data[0] : {};
        //         if(Object.keys(this.cmsData).length > 0) {
        //             this.counter = 0;
        //             let bannerTextData = this.cmsData.bannerText;
        //             if (bannerTextData != null) {
        //                 let dataArray = JSON.parse(bannerTextData);
        //                 let index = 0;
        //                 for (const [key, value] of Object.entries(dataArray)) {
        //                     this.specificationKey[index] = key;
        //                     this.specificationValue[index] = value;
        //                     this.specificationArray[this.specificationKey[index]] = this.specificationValue[index];
        //                     index++;
        //                     this.counter = this.counter + 1;
        //                 }
        //             }
        //         }
        //         else {
        //             this.counter = 1;
        //         }
        //     }
        //     else {
        //         this.cmsData = {};
        //     }
        // },
        addSpecification() {
            let index = this.counter - 1;
            if (!this.specificationKey[index]) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter features!"
                });
                return;
            }
            if (!this.specificationValue[index]) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter icon!"
                });
                return;
            }
            this.counter = this.counter + 1;
        },
        finalSpecification() {
            if (!this.price) {
                this.showToaster('Please enter price!')
                return false;
            }
            if (!this.packageType) {
                this.showToaster('Please select package type!')
                return false;
            }
            if (!this.packageName) {
                this.showToaster('Please enter package name!')
                return false;
            }
            if (!this.description) {
                this.showToaster('Please enter description!')
                return false;
            }
            if (!this.buttonText) {
                this.showToaster('Please enter button text!')
                return false;
            }
            if (!this.buttonLink) {
                this.showToaster('Please enter button link!')
                return false;
            }
            let index = this.counter - 1;
            if (!this.specificationKey[index]) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter features!"
                });
                return false;
            }
            if (!this.specificationValue[index]) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter icon name!"
                });
                return false;
            }
            return true;
        },
        deleteSpecificationRow(index) {
            delete this.specificationArray[this.specificationKey[index]];
            this.specificationKey.splice(index, 1);
            this.specificationValue.splice(index, 1);
            this.counter = this.counter - 1;
        },
        addEntity: async function () {
            this.specificationArray = {};
            for (let i = 0; i < this.counter; i++) {
                this.specificationArray[this.specificationKey[i]] = this.specificationValue[i];
            }
            if (!this.finalSpecification()) {
                return;
            }
            // console.log("Final specification array: ", JSON.stringify(this.specificationArray));
            this.$swal.showLoading();
            let data = {
                price: this.price,
                packageType: this.packageType,
                packageName: this.packageName,
                description: this.description,
                buttonText: this.buttonText,
                buttonLink: this.buttonLink,
                features: JSON.stringify(this.specificationArray)
            };
            let url = this.$serverURL + this.$api.home.easyPaymentPackage;
            this.$swal.showLoading();
            await this.postData(url, data).then(async (response) => {
                if (response.statusCode == 201) {
                    
                    this.showToaster(response.message, 'success');
                    this.$router.push('/easy-payment-crud');
                   
                }
            });
        }
    }
}