<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb mb-0 p-0">
                        <li class="breadcrumb-item">
                            <router-link to="/home"><i class="bx bx-home-alt"></i></router-link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">
                            Review Section
                        </li>
                    </ol>
                </nav>
                <div class="ms-auto">
                    <div class="btn-group">
                        <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#addModal"
                            >
                            <i class="bx bxs-plus-square"></i>Add Review
                        </button>
                    </div>
                    <!-- Add Modal -->
                    <div class="modal fade" id="addModal" tabindex="-1" aria-labelledby="addModalLabel" aria-hidden="true">
                        <form @submit.prevent="addReview">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="addModalLabel">Add Review</h5>
                                        <button type="button" id="closeButtonAdd" class="btn-close" data-bs-dismiss="modal"
                                            aria-label="Close"></button>
                                    </div>
                                    <div class="modal-body">
                                        <label class="form-label">Review Message:</label>
                                        <textarea type="text" v-model="reviewMessage" class="form-control"
                                            placeholder="Enter review" rows="3"></textarea>
                                    </div>
                                    <div class="modal-body">
                                        <label class="form-label">User Name:</label>
                                        <input type="text" v-model="userName" class="form-control"
                                            placeholder="Enter user name" />
                                    </div>
                                    <div class="modal-body">
                                        <div class="row">
                                            <div class="col-12">
                                                <label for="inputProductTitle" class="form-label">
                                                    User Photo:
                                                    <strong>
                                                        [Preferred Image Size: 71X71, Max Upload Limit: 1MB]
                                                    </strong>
                                                </label>
                                            </div>
                                            <div class="col-12">
                                                <img v-if="preview.image" style="position: relative; left: 11px"
                                                    :src="preview.image" height="100" />
                                                <img v-else-if="userPhoto" style="position: relative; left: 11px"
                                                    :src="userPhoto" height="100" />
                                            </div>
                                        </div>

                                        <input class="form-control mt-3" type="file" ref="imageAdd" accept="image/*"
                                            @change="handleUploadImage('userPhoto', 'image', 'imageAdd', $event)" />
                                    </div>
                                    <div class="modal-body">
                                        <label class="form-label">Icon:</label>
                                        <input type="text" v-model="icon" class="form-control"
                                            placeholder="Enter icon classname" />
                                    </div>
                                    <div class="modal-body">
                                        <label class="form-label">Icon Text:</label>
                                        <input type="text" v-model="iconText" class="form-control"
                                            placeholder="Enter icon text" />
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                                            Close
                                        </button>
                                        <button type="submit" class="btn btn-primary">
                                            Add
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <!-- Add Modal -->

                    <!-- Edit Modal -->
                    <div class="modal fade" id="editModal" tabindex="-1" aria-labelledby="addModalLabel" aria-hidden="true">
                        <form @submit.prevent="updateReview">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="addModalLabel">Edit Review</h5>
                                        <button type="button" id="closeButtonEdit" class="btn-close" data-bs-dismiss="modal"
                                            aria-label="Close"></button>
                                    </div>
                                    <div class="modal-body">
                                        <label class="form-label">Review Message:</label>
                                        <textarea type="text" v-model="single.reviewMessage" class="form-control"
                                            placeholder="Enter review" rows="3"></textarea>
                                    </div>
                                    <div class="modal-body">
                                        <label class="form-label">User Name:</label>
                                        <input type="text" v-model="single.userName" class="form-control"
                                            placeholder="Enter user name" />
                                    </div>
                                    <div class="modal-body">
                                        <div class="row">
                                            <div class="col-12">
                                                <label for="inputProductTitle" class="form-label">
                                                    Update User Photo:
                                                    <strong>
                                                        [Preferred Image Size: 71X71, Max Upload Limit: 1MB]
                                                    </strong>
                                                </label>
                                            </div>
                                            <div class="col-12">
                                                <img v-if="preview.image" style="position: relative; left: 11px"
                                                    :src="preview.image" height="100" />
                                                <img v-else-if="single.userPhoto" style="position: relative; left: 11px"
                                                    :src="single.userPhoto" height="100" />
                                            </div>
                                        </div>

                                        <input class="form-control mt-3" type="file" ref="imageEdit" accept="image/*"
                                            @change="handleUploadImage('userPhoto', 'image', 'imageEdit', $event)" />
                                    </div>
                                    <div class="modal-body">
                                        <label class="form-label">Icon:</label>
                                        <input type="text" v-model="single.icon" class="form-control"
                                            placeholder="Enter icon classname" />
                                    </div>
                                    <div class="modal-body">
                                        <label class="form-label">Icon Text:</label>
                                        <input type="text" v-model="single.iconText" class="form-control"
                                            placeholder="Enter icon text" />
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                                            Close
                                        </button>
                                        <button type="submit" class="btn btn-primary">
                                            Update
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <!-- Edit Modal -->
                </div>
            </div>
            <h6 class="mb-0 text-uppercase">Review List</h6>
            <hr />
            <div class="card">
                <div class="card-body">
                    <div class="table-responsive">
                        <table id="example" class="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th>SL</th>
                                    <th>Review</th>
                                    <th>User Name</th>
                                    <th>User Photo</th>
                                    <th>Icon</th>
                                    <th>Icon Text</th>
                                    <th>Date</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(data, index) in dataList" :key="index">
                                    <td>{{ index + 1 }}</td>
                                    <td>{{ data.reviewMessage.length > 50 ? data.reviewMessage.slice(0, 50) + '...' : data.reviewMessage }}</td>
                                    <td>{{ data.userName }}</td>
                                    <td>
                                        <img :src="data.userPhoto" height="100" />
                                    </td>
                                    <td>{{ data.icon }}</td>
                                    <td>{{ data.iconText }}</td>
                                    <td>{{ $filters.formatDate(data.createdAt) }}</td>
                                    <td>
                                        <div class="d-flex order-actions">
                                            <a href="javascript:void(0);" @click="editReview(data)" data-bs-toggle="modal"
                                                data-bs-target="#editModal" class="edit-button">
                                                <i class="bx bxs-edit text-white"></i>
                                            </a>
                                            <a href="javascript:void(0);" @click="deleteReview(data.id)"
                                                class="ms-3 delete-button"><i class="bx bxs-trash text-white"></i></a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th>SL</th>
                                    <th>Review</th>
                                    <th>User Name</th>
                                    <th>User Photo</th>
                                    <th>Icon</th>
                                    <th>Icon Text</th>
                                    <th>Date</th>
                                    <th>Action</th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <!-- Description Modal -->
        <div class="modal fade" id="showModal" tabindex="-1" aria-labelledby="showModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="showModalLabel">Description</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <p class="form-control mb-1" type="text" v-html="description"></p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script src='../js/crud.js'></script>


<style scoped>
@import "../../../../../../../public/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css";

.edit-button {
    background-color: #17a00e;
}

.delete-button {
    background-color: #f41127;
}

.metaModal {
    color: black;
    cursor: pointer;
    text-decoration: none;
    display: block;
}

.metaModal:hover {
    cursor: pointer;
    color: #09f;
}
</style>
