<template>
	<div class="page-wrapper">
		<div class="page-content">
			<div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
				<div class="breadcrumb-title pe-3">Home Page Benefits & Advantage Section CMS</div>
				<div class="ps-3">
					<nav aria-label="breadcrumb">
						<ol class="breadcrumb mb-0 p-0">
							<li class="breadcrumb-item">
								<router-link to="/dashboard">
									<i class="bx bx-home-alt"></i>
								</router-link>
							</li>
							<li class="breadcrumb-item active" aria-current="page">Home Page Benefits & Advantage Section CMS</li>
						</ol>
					</nav>
				</div>
			</div>
			<form @submit.prevent="updateCms()">
				<div class="card">
					<div class="card-body">
						<h5 class="card-title">Home Page Benefits & Advantage Section CMS</h5>
						<hr />
						<div class="form-body mt-4">
							<div class="row">
								<div class="col-lg-8 mb-3">
									<div class="border border-3 p-4 rounded">
										<div class="row">
											<div class="col-12 mb-3">
												<label class="form-label">Title:</label>
												<input type="text" v-model="cmsData.title" class="form-control" id="inputPageHeaderTitle" placeholder="Enter title" />
											</div>
											<div class="col-12 mb-3">
												<label class="form-label">Section Title:</label>
												<input type="text" v-model="cmsData.sectionTitle" class="form-control" id="inputPageHeaderTitle" placeholder="Enter subtitle" />
											</div>
											<div class="col-12 mb-3">
												<label class="form-label">Section Subtitle:</label>
												<input type="text" v-model="cmsData.sectionSubTitle" class="form-control" id="inputPageHeaderTitle" placeholder="Enter description" />
											</div>
										</div>
									</div>
								</div>
								<div class="d-grid col-12 col-lg-8">
									<button type="submit" class="btn btn-primary">Save</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>
	</div>
</template>

<script src="../js/cms.js"></script>