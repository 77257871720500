// import { authHeader } from '../../../auth';

export default {
    name: "Category",
    data() {
        return {
            requestUrl: this.$serverURL + this.$api.content.pressLink,
            dataList: [],
            formData: {
                title: "",
                link: "",
                icon: "",
            },
            singleData: {
                title: "",
                link: "",
                icon: "",
            },
        }
    },
    async created() {
        document.title = "Transaction Bee - Press Link Content";
        await this.getData();
        this.loadScripts();
    },
    methods: {
        async getData() {
            await this.getDataList(this.requestUrl).then(response => {
                if (response.statusCode == 200) {
                    this.dataList = response.data;
                }
            });
        },

        checkValidation(data) {
            if (!data.title) {
                this.showToaster('Please enter title.', 'info')
                return false;
            } else if (!data.link) {
                this.showToaster('Please enter link.', 'info')
                return false;
            } else if (!data.icon) {
                this.showToaster('Please enter icon.', 'info')
                return false;
            }
            return true;
        },

        async createContent() {
            try {
                if (this.checkValidation(this.formData)) {
                    const data = {
                        title: this.formData.title,
                        link: this.formData.link,
                        icon: this.formData.icon,
                    }
                    this.$swal.showLoading();
                    await this.postData(this.requestUrl, data).then(async (response) => {
                        if (response.statusCode == 201) {
                            this.formData = {
                                title: "",
                                link: "",
                                icon: "",
                            };
                            this.showToaster(response.message, 'success');
                            await this.getData();
                            document.getElementById('add-close-btn').click();
                        }
                    });
                }
            } catch (error) {
                console.log(error);
            }
        },
        editData(data) {
            this.singleData = data;
        },
        update: async function () {
            if (this.checkValidation(this.singleData)) {
                const data = {
                    title: this.singleData.title,
                    link: this.singleData.link,
                    icon: this.singleData.icon,
                }
                let url = this.requestUrl + '/' + this.singleData.id;
                this.$swal.showLoading();
                await this.updateData(url, data).then(async (response) => {
                    if (response.statusCode == 200) {
                        this.$swal.close();
                        this.singleData = {};
                        await this.getData();
                        document.getElementById('edit-close-btn').click();
                    }
                });
            }
        },

        async deleteData(id) {
            const url = this.requestUrl + '/' + id
            await this.deleteContent(url).then(async (response) => {
                if (response == true) {
                    await this.getData();
                }
            })
        }
    }
}