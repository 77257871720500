<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <div class="breadcrumb-title pe-3">Package List</div>
                <div class="ps-3">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb mb-0 p-0">
                            <li class="breadcrumb-item">
                                <router-link to="/home">
                                    <i class="bx bx-home-alt"></i>
                                </router-link>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">Package List</li>
                        </ol>
                    </nav>
                </div>
                <div class="ms-auto">
                    <div class="btn-group">
                        <button  @click="$router.push('/add-new-package')" type="button" class="btn btn-primary">
                            <i class="bx bxs-plus-square"></i>Add New Package
                        </button>
                    </div>
                </div>
            </div>
            <h6 class="mb-0 text-uppercase">Package List</h6>
            <hr />
            <div class="card">
                <div class="card-body">
                    <div class="table-responsive">
                        <table id="example" class="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th>Sl</th>
                                    <th>Price</th>
                                    <th>Package Type</th>
                                    <th>Package Name</th>
                                    <th>Description</th>
                                    <!-- <th>Features</th> -->
                                    <th>Button Text</th>
                                    <th>Button Link</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(data, index) in dataList" :key="index">
                                    <td>{{ index + 1 }}</td>
                                    <td>{{ data.price }}</td>
                                    <td>{{ data.packageType }}</td>
                                    <td>{{ data.packageName }}</td>
                                    <td>{{ data.description.length > 30 ? data.description.slice(0, 30) + '...' : data.description }}</td>
                                    <!-- <td>{{ data.features.length > 50 ? data.features.slice(0, 50) + '...' : data.features }}</td> -->
                                    <td>{{ data.buttonText }}</td>
                                    <td>{{ data.buttonLink }}</td>
                                    <td>
                                        <div class="d-flex order-actions">
                                            <a href="javascript:void(0);" @click="$router.push(`/edit-package/${data.id}`)" class="edit-button">
                                                <i class="bx bxs-edit"></i>
                                            </a>
                                            <a href="javascript:void(0);" v-on:click="deleteData(data.id)"
                                                class="delete-button ms-2">
                                                <i class="bx bxs-trash"></i>
                                            </a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th>Sl</th>
                                    <th>Price</th>
                                    <th>Package Type</th>
                                    <th>Package Name</th>
                                    <th>Description</th>
                                    <!-- <th>Features</th> -->
                                    <th>Button Text</th>
                                    <th>Button Link</th>
                                    <th>Action</th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<style scoped>
@import "/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css";

.edit-button {
    background-color: #17a00e;
}

.delete-button {
    background-color: #f41127;
}
</style>

<script src="../js/crud.js"></script>