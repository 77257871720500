// import { authHeader } from '../../../auth';

export default {
    name: "Schedule Transaction Limit",
    data() {
        return {
            requestUrl: this.$serverURL + this.$api.schedule.transactionLimit,
            dataList: [],
            formData: {
                limit: "",
            },
            singleData: {
                limit: ""
            },
        }
    },
    async created() {
        document.title = "Transaction Bee - Schedule Transaction Limit";
        await this.getData();
        this.loadScripts();
    },
    methods: {
        async getData() {
            await this.getDataList(this.requestUrl).then(response => {
                if (response.statusCode == 200) {
                    this.dataList = response.data;
                }
            });
        },

        checkValidation(data) {
            if (!data.limit) {
                this.showToaster('Please enter transaction limit!', 'info')
                return false;
            }
            return true;
        },

        async createContent() {
            try {
                if (this.checkValidation(this.formData)) {
                    const data = {
                        limit: this.formData.limit,
                    }
                    this.$swal.showLoading();
                    await this.postData(this.requestUrl, data).then(async (response) => {
                        if (response.statusCode == 201) {
                            this.formData = {
                                limit: ""
                            };
                            this.showToaster(response.message, 'success');
                            await this.getData();
                            document.getElementById('add-close-btn').click();
                        }
                    });
                }
            } catch (error) {
                console.log(error);
            }
        },
        editData(data) {
            this.singleData = { ...data };
        },
        update: async function () {
            if (this.checkValidation(this.singleData)) {
                const data = {
                    limit: this.singleData.limit,
                }
                let url = this.requestUrl + '/' + this.singleData.id;
                this.$swal.showLoading();
                await this.updateData(url, data).then(async (response) => {
                    if (response.statusCode == 200) {
                        this.showToaster(response.message, 'success');
                        this.singleData = {};
                        await this.getData();
                        document.getElementById('edit-close-btn').click();
                    }
                });
            }
        },

        async deleteData(id) {
            const url = this.requestUrl + '/' + id
            await this.deleteContent(url).then(async (response) => {
                if (response == true) {
                    await this.getData();
                }
            })
        }
    }
}