export default {
    data() {
        return {
            url: this.$serverURL + this.$api.cms.pageMetaCMS,
            cmsData: {},
        }
    },
    async created() {
        document.title = "Transaction Bee - Site Page Meta CMS";
        this.getData();

    },
    methods: {
        async getData() {
            let response = await this.getCmsData(this.url);
            if (response.statusCode == 200) {
                this.cmsData = (response.data.length > 0) ? response.data[0] : {};
            }
            else {
                this.cmsData = {};
            }
        },
        async updateCms() {
            try {
                let data = {
                    'signupPageMetaKeyword': this.cmsData.signupPageMetaKeyword,
                    'signupPageMetaDescription': this.cmsData.signupPageMetaDescription,
                    'loginPageMetaKeyword': this.cmsData.loginPageMetaKeyword,
                    'loginPageMetaDescription': this.cmsData.loginPageMetaDescription,
                    'homePageMetaKeyword': this.cmsData.homePageMetaKeyword,
                    'homePageMetaDescription': this.cmsData.homePageMetaDescription,
                    'aboutPageMetaKeyword': this.cmsData.aboutPageMetaKeyword,
                    'aboutPageMetaDescription': this.cmsData.aboutPageMetaDescription,
                    'pricingPageMetaKeyword': this.cmsData.pricingPageMetaKeyword,
                    'pricingPageMetaDescription': this.cmsData.pricingPageMetaDescription,
                    'contactPageMetaKeyword': this.cmsData.contactPageMetaKeyword,
                    'contactPageMetaDescription': this.cmsData.contactPageMetaDescription,
                    'careerPageMetaKeyword': this.cmsData.careerPageMetaKeyword,
                    'careerPageMetaDescription': this.cmsData.careerPageMetaDescription,
                    'careerDetailsPageMetaKeyword': this.cmsData.careerDetailsPageMetaKeyword,
                    'careerDetailsPageMetaDescription': this.cmsData.careerDetailsPageMetaDescription,
                    'faqPageMetaKeyword': this.cmsData.faqPageMetaKeyword,
                    'faqPageMetaDescription': this.cmsData.faqPageMetaDescription,
                    'faqDetailsPageMetaKeyword': this.cmsData.faqDetailsPageMetaKeyword,
                    'faqDetailsPageMetaDescription': this.cmsData.faqDetailsPageMetaDescription,
                    'partnerPageMetaKeyword': this.cmsData.partnerPageMetaKeyword,
                    'partnerPageMetaDescription': this.cmsData.partnerPageMetaDescription,
                    'pressKitPageMetaKeyword': this.cmsData.pressKitPageMetaKeyword,
                    'pressKitPageMetaDescription': this.cmsData.pressKitPageMetaDescription,
                    'termsPageMetaKeyword': this.cmsData.termsPageMetaKeyword,
                    'termsPageMetaDescription': this.cmsData.termsPageMetaDescription,
                    'securityPageMetaKeyword': this.cmsData.securityPageMetaKeyword,
                    'securityPageMetaDescription': this.cmsData.securityPageMetaDescription,
                    'privacyPageMetaKeyword': this.cmsData.privacyPageMetaKeyword,
                    'privacyPageMetaDescription': this.cmsData.privacyPageMetaDescription,
                    'schedulePageMetaKeyword': this.cmsData.schedulePageMetaKeyword,
                    'schedulePageMetaDescription': this.cmsData.schedulePageMetaDescription,
                };
                this.$swal.showLoading();
                let response = await this.createUpdateCMS(this.url, data);
                if (response.statusCode == 200 || response.statusCode == 201) {
                    
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
                await this.getData();
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            } finally {
                this.$swal.hideLoading();
            }
        }
    }
}