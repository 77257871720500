import { createApp } from 'vue';
import App from './App.vue';
import CKEditor from '@ckeditor/ckeditor5-vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import router from './routes/routes';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import Multiselect from '@vueform/multiselect';
import '@vueform/multiselect/themes/default.css';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import moment from 'moment';
import mixins from './mixin';
import VueGoogleMaps from '@fawmi/vue-google-maps';
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import VueMask from '@devindex/vue-mask';
const app = createApp(App);

app.use(VueAxios, axios);
app.use(VueSweetalert2);
app.component('Multiselect', Multiselect);
app.component('v-select', vSelect);
app.component('QuillEditor', QuillEditor)
app.use(CKEditor);
app.use(VueMask);
app.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyAehQpebrDKRfTWH-PKnmQPNf5yZM3VIyM',
        libraries: 'places'
    }
});

const server = require('../config/server.json');
app.config.globalProperties.$api = require('../config/api.json');
app.config.globalProperties.$axios = axios;

app.config.globalProperties.$filters = {
    formatDateTime(date) {
        return moment(String(date)).format('DD/MM/YYYY hh:mm:ss A');
    },
    formatDate(date) {
        return moment(String(date)).format('MM/DD/YYYY');
    }
};

if (location.hostname === 'localhost' || location.hostname === '127.0.0.1') {
    app.config.globalProperties.$serverURL = server.SERVER_HOST;
    app.config.globalProperties.$hostURL = server.STAGING_HOST;
} else {
    app.config.globalProperties.$hostURL = server.HOST;
    app.config.globalProperties.$serverURL = server.SERVER_HOST;
}

app.mixin(mixins);
app.use(router).mount('#app');
