import { authHeader } from './auth';

export default {
    data() {
        return {
            cmsData: []
        }
    },
    methods: {
        loadScripts() {
            var scripts = [
                "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
                "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
                "/core/assets/js/table-datatable.js"
            ];
            scripts.forEach(script => {
                let tag = document.createElement("script");
                tag.setAttribute("src", script);
                document.head.appendChild(tag);
            });
            let style = document.createElement('link');
            style.type = "text/css";
            style.rel = "stylesheet";
            style.href = '/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css';
            document.head.appendChild(style);
        },
        getCmsData: async function (url) {
            try {
                let config = {
                    method: "GET",
                    url: url,
                    headers: authHeader()
                }
                let response = await this.$axios(config);
                return response.data;
            } catch (error) {
                console.log(error);
            }
        },
        createUpdateCMS: async function (url, data) {
            try {
                let response = await this.$axios({
                    method: 'POST',
                    url: url,
                    data: data,
                    headers: authHeader()
                });
                return response.data;
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            }
        },
        getDataList: async function (url) {
            try {
                let config = {
                    method: "GET",
                    url: url,
                    headers: authHeader()
                }
                let response = await this.$axios(config);
                return response.data;
            } catch (error) {
                console.log(error);
            }
        },
        postData: async function (url, data) {
            try {
                let config = {
                    method: "POST",
                    url: url,
                    data: data,
                    headers: authHeader()
                };
                let response = await this.$axios(config);
                return response.data;
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            }
        },
        updateData: async function (url, data) {
            try {
                let config = {
                    method: "PATCH",
                    url: url,
                    data: data,
                    headers: authHeader()
                };
                let response = await this.$axios(config);
                return response.data;
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            }
        },
        deleteContent: async function (url) {
            let isSuccess = false;
            await this.$swal.fire({
                title: 'Are you sure?',
                text: "You will not be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Confirm'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        this.$swal.showLoading();
                        let config = {
                            method: "DELETE",
                            url: url,
                            headers: authHeader()
                        };
                        await this.$axios(config).then((response) => {
                            if (response.data.statusCode == 200) {
                                isSuccess = true;
                                this.showToaster(response.data.message, 'success');
                            }
                        }).catch(error => {
                            this.$swal.fire({
                                icon: "error",
                                text: error.response.data.message
                            });
                        });
                    } catch (error) {
                        this.$swal.fire({
                            icon: "error",
                            text: error.response.data.message
                        });
                    } finally {
                        this.$swal.hideLoading();
                    }
                }
            })
            return isSuccess;
        },
        getSingleData: async function (url) {
            try {
                let config = {
                    method: "GET",
                    url: url,
                    headers: authHeader()
                }
                let response = await this.$axios(config);
                return response.data;
            } catch (error) {
                console.log(error);
            }
        },
        showToaster(msg, icon) {
            this.$swal.fire({
                toast: true,
                position: 'top-end',
                text: msg,
                icon: icon,
                showConfirmButton: false,
                timer: 3000,
                animation: false,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            });
        }
    }
}