<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <div class="breadcrumb-title pe-3">Office Address</div>
                <div class="ps-3">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb mb-0 p-0">
                            <li class="breadcrumb-item"><router-link to="/home"><i class="bx bx-home-alt"></i></router-link>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">Office Address</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div class="card">
                <div class="card-body p-4">
                    <h5 class="card-title">Edit Office Address</h5>
                    <hr />
                    <div class="form-body mt-4">
                        <div class="row">
                            <div class="col-lg-8">
                                <div class="border border-3 p-4 rounded">
                                    <div class="mb-3">
                                        <label class="form-label">Title:</label>
                                        <input type="text" v-model="title" class="form-control">
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Address:</label>
                                        <GMapAutocomplete @place_changed="setAddress" :value="addressData.address"
                                            @input="addressData.address = $event.target.value" class="form-control">
                                        </GMapAutocomplete>
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">City:</label>
                                        <input type="text" v-model="addressData.city" class="form-control">
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">State:</label>
                                        <input type="text" v-model="addressData.state" class="form-control">
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Postal Code:</label>
                                        <input type="text" v-model="addressData.postalCode" class="form-control">
                                    </div>
                                    <div class="d-grid">
                                        <button v-on:click="save" type="button" class="btn btn-primary">Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="../js/edit-office-address.js"></script>