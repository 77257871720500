export default {
    data() {
        return {
            url: this.$serverURL + this.$api.cms.aboutUsPageCms,
            cmsData: {
                previewImage: "",
                previewImage2: "",
                videoLink: "",
            },
            
            preview: {
                videoLink: ""
            }
        }
    },
    async created() {
        document.title = "Transaction Bee - About Us Page CMS";
        this.getData();
    },
    methods: {
        async getData() {
            let response = await this.getCmsData(this.url);
            if (response.statusCode == 200) {
                this.cmsData = (response.data.length > 0) ? response.data[0] : {};
            }
            else {
                this.cmsData = {};
            }
        },
        handleUploadImage(imageKey, ref, event) {
            this.cmsData[imageKey] = event.target.files[0];
            let input = this.$refs[ref];
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewImage = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        handleUploadImage2(imageKey, ref, event) {
            this.cmsData[imageKey] = event.target.files[0];
            let input = this.$refs[ref];
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewImage2 = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        handleUploadVideo(videoKey, previewVideoKey, ref, event) {
            this.cmsData[videoKey] = event.target.files[0];
            let input = this.$refs[ref];
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader;
                reader.onload = e => {
                    this.preview[previewVideoKey] = e.target.result;
                }
                reader.readAsDataURL(file[0]);
            }
        },
        async updateCms() {
            try {
                const formData = new FormData();
                formData.append('pageTitle', this.cmsData.pageTitle);
                formData.append('pageSubTitle', this.cmsData.pageSubTitle);
                formData.append('sectionOneTitle', this.cmsData.sectionOneTitle);
                formData.append('sectionOneSubTitle', this.cmsData.sectionOneSubTitle);
                formData.append('sectionTwoTitle', this.cmsData.sectionTwoTitle);
                formData.append('videoLink', this.cmsData.videoLink);
                formData.append('buttonText', this.cmsData.buttonText);
                formData.append('buttonLink', this.cmsData.buttonLink);
                formData.append('heroBackgroundImage', this.cmsData.heroBackgroundImage);
                formData.append('leaderBackgroundImage', this.cmsData.leaderBackgroundImage);
                this.$swal.showLoading();
                let response = await this.createUpdateCMS(this.url, formData);
                if (response.statusCode == 200 || response.statusCode == 201) {
                    this.$refs.videoLink.value = null;
                    this.preview = {
                        videoLink: ""
                    }
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
                await this.getData();
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            } finally {
                this.$swal.hideLoading();
            }
        }
    }
}