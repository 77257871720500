export default {
    name: "AddOfficeAddress",
    data() {
        return {
            title: '',
            addressData: {},
            setURL: this.$serverURL + this.$api.officeAddress.requestURL
        }
    },
    async created() {
        document.title = "Transaction Bee - Office Address";
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script =>
        {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
        let style = document.createElement('link');
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = '/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css';
        document.head.appendChild(style);
    },
    methods: {
        setAddress(locationData) {
            let location = locationData.formatted_address;
            let addressComponent = locationData.address_components;
            let locationSplit = location.split(',');
            if (locationSplit[0].trim() != locationData.name) {
                location = locationData.name + ', ' + location;
            }
            this.addressData.address = locationData.name;

            this.addressData.city = "";
            this.addressData.postalCode = "";
            this.addressData.state = "";

            for (let i = 0; i < addressComponent.length; i++) {
                if (addressComponent[i].types[0] == "locality") {
                    this.addressData.city = addressComponent[i].long_name;
                } else if (addressComponent[i].types[0] == "postal_code") {
                    this.addressData.postalCode = addressComponent[i].long_name;
                } else if (addressComponent[i].types[0] == "administrative_area_level_1") {
                    this.addressData.state = addressComponent[i].short_name;
                }
            }
        },
        checkValidation: function () {
            if (!this.title) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please provide address title.",
                });
                return false;
            } else if (!this.addressData.address) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please provide office address.",
                });
                return false;
            } else if (!this.addressData.city) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please provide city.",
                });
                return false;
            } else if (!this.addressData.state) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please provide state.",
                });
                return false;
            } else if (!this.addressData.postalCode) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please provide address postal code.",
                });
                return false;
            } else {
                return true;
            }
        },
        save: async function() {
            if (this.checkValidation()) {
                const data = {
                    title: this.title,
                    streetAddress: this.addressData.address,
                    city: this.addressData.city,
                    state: this.addressData.state,
                    postalCode: this.addressData.postalCode
                }
                let url = this.setURL;
                this.$swal.showLoading();
                await this.postData(url, data).then(async (response) => {
                    if (response.statusCode == 201) {
                        this.addressData = {
                            address: "",
                            city: "",
                            state: "",
                            postalCode: ""
                        };
                        this.showToaster(response.message, 'success');
                        this.$router.push("/office-address");
                    }
                });
            }

        }
    }
}