export default {
    name: "Unapproved Users",
    data() {
        return {
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            password: '',
            confirmPassword: '',
            inputTypePassword: "password",
            inputTypeConfirmPassword: "password",
            iconStatus: true,
            iconFlag: true,
            setURL: this.$serverURL + this.$api.user.userList,
            singleUser: {},
            dataList: [],
        }
    },
    async created() {
        document.title = "Transaction Bee - Unapproved User";
        await this.getUserList();
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
        let style = document.createElement('link');
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = '/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css';
        document.head.appendChild(style);
    },
    methods: {
        async getUserList() {
            const data = await this.getDataList(this.setURL);
            this.dataList = data.data.filter((el) => (el.status == "Pending"));
        },

        updateStatus: async function (data, status, index) {
            await this.$swal.fire({
                title: 'Are you sure?',
                text: "You will not be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Confirm'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        this.$swal.showLoading();
                        let payload = {
                            status: status,
                        };
                        let url = this.$serverURL + this.$api.user.userStatusUpdate + data.id;
                        let response = await this.updateData(url, payload);
                        if (response.statusCode == 200) {
                            this.$swal.fire({
                                toast: true,
                                position: 'top-end',
                                text: `User ${status == "Disabled" ? "Rejected" : status} Successfully!`,
                                icon: "success",
                                showConfirmButton: false,
                                timer: 3000,
                                animation: false,
                                timerProgressBar: true,
                                didOpen: (toast) => {
                                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                                }
                            });
                            this.getUserList();
                        }
                    } catch (error) {
                        this.$swal.fire({
                            icon: "error",
                            text: error.response.data.message
                        });
                    } finally {
                        this.$swal.hideLoading();
                    }
                } else {
                    this.dataList[index].status = "Pending";
                }
            })
        },

        deleteUser: async function (id) {
            let url = this.$serverURL + this.$api.user.singleUserURL + id;
            this.$swal.showLoading();
            await this.deleteContent(url).then(async (response) => {
                if (response == true) {
                    let userData = await this.getDataList(this.setURL);
                    this.dataList = userData.data;
                }
            })
        }
    }
}