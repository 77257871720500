<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb mb-0 p-0">
                        <li class="breadcrumb-item">
                            <router-link to="/home"><i class="bx bx-home-alt"></i></router-link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">
                            Benefits
                        </li>
                    </ol>
                </nav>
                <div class="ms-auto">
                    <div class="btn-group">
                        <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#addModal" :disabled="dataList.length>3">
                            <i class="bx bxs-plus-square"></i>Add Benefit
                        </button>
                    </div>
                    <!-- Add Modal -->
                    <div class="modal fade" id="addModal" tabindex="-1" aria-labelledby="addModalLabel" aria-hidden="true">
                        <form @submit.prevent="addBenefit">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="addModalLabel">Add Benefit</h5>
                                        <button type="button" id="closeButtonAdd" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div class="modal-body">
                                        <div class="row">
                                            <div class="col-12">
                                                <label for="inputProductTitle" class="form-label">
                                                    Image:
                                                    <strong>
                                                        [Preferred Image Size: 71X71, Max Upload Limit: 1MB]
                                                    </strong>
                                                </label>
                                            </div>
                                            <div class="col-12">
                                                <img
                                                    v-if="preview.image"
                                                    style="position: relative; left: 11px"
                                                    :src="preview.image"
                                                    height="100"
                                                />
                                                <img
                                                    v-else-if="image"
                                                    style="position: relative; left: 11px"
                                                    :src="image"
                                                    height="100"
                                                />
                                            </div>
                                        </div>

                                        <input
                                            class="form-control mt-3"
                                            type="file"
                                            ref="imageAdd"
                                            accept="image/*"
                                            @change="handleUploadImage('image', 'image', 'imageAdd', $event)"
                                        />
                                    </div>
                                    <div class="modal-body">
                                        <label class="form-label">Title:</label>
                                        <input type="text" v-model="title" class="form-control" placeholder="Enter title" />
                                    </div>
                                    <div class="modal-body">
                                        <label class="form-label">Description:</label>
                                        <input type="text" v-model="description" class="form-control" placeholder="Enter description" />
                                    </div>
                                    <div class="modal-body">
                                        <label class="form-label">Button Text:</label>
                                        <input type="text" v-model="buttonText" class="form-control" placeholder="Enter button text" />
                                    </div>
                                    <div class="modal-body">
                                        <label class="form-label">Button Link:</label>
                                        <input type="text" v-model="buttonLink" class="form-control" placeholder="Enter button link" />
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                                            Close
                                        </button>
                                        <button type="submit" class="btn btn-primary">
                                            Add
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <!-- Add Modal -->

                    <!-- Edit Modal -->
                    <div class="modal fade" id="editModal" tabindex="-1" aria-labelledby="addModalLabel" aria-hidden="true">
                        <form @submit.prevent="updateBenefit">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="addModalLabel">Edit Section Two</h5>
                                        <button type="button" id="closeButtonEdit" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div class="modal-body">
                                        <div class="row">
                                            <div class="col-12">
                                                <label for="inputProductTitle" class="form-label">
                                                    Image:
                                                    <strong>
                                                        [Preferred Image Size: 71X71, Max Upload Limit: 1MB]
                                                    </strong>
                                                </label>
                                            </div>
                                            <div class="col-12">
                                                <img
                                                    v-if="preview.image"
                                                    style="position: relative; left: 11px"
                                                    :src="preview.image"
                                                    height="100"
                                                />
                                                <img
                                                    v-else-if="single.image"
                                                    style="position: relative; left: 11px"
                                                    :src="single.image"
                                                    height="100"
                                                />
                                            </div>
                                        </div>

                                        <input
                                            class="form-control mt-3"
                                            type="file"
                                            ref="imageEdit"
                                            accept="image/*"
                                            @change="handleUploadImage('image', 'image', 'imageEdit', $event)"
                                        />
                                    </div>
                                    <div class="modal-body">
                                        <label class="form-label">Title:</label>
                                        <input type="text" v-model="single.title" class="form-control" placeholder="Enter title" />
                                    </div>
                                    <div class="modal-body">
                                        <label class="form-label">Description:</label>
                                        <input type="text" v-model="single.description" class="form-control" placeholder="Enter description" />
                                    </div>
                                    <div class="modal-body">
                                        <label class="form-label">Button Text:</label>
                                        <input type="text" v-model="single.buttonText" class="form-control" placeholder="Enter button text" />
                                    </div>
                                    <div class="modal-body">
                                        <label class="form-label">Button Link:</label>
                                        <input type="text" v-model="single.buttonLink" class="form-control" placeholder="Enter button link" />
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                                            Close
                                        </button>
                                        <button type="submit" class="btn btn-primary">
                                            Update
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <!-- Edit Modal -->
                </div>
            </div>
            <h6 class="mb-0 text-uppercase">Benefit List</h6>
            <hr />
            <div class="card">
                <div class="card-body">
                    <div class="table-responsive">
                        <table id="example" class="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th>SL</th>
                                    <th>Image</th>
                                    <th>Title</th>
                                    <th>Description</th>
                                    <th>Button Text</th>
                                    <th>Button Link</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(data, index) in dataList" :key="index">
                                    <td>{{ index + 1 }}</td>
                                    <td>
                                        <img :src="data.image" height="100"/>
                                    </td>
                                    <td>{{ data.title }}</td>
                                    <td>{{ data.description.length > 30 ? data.description.slice(0, 30) + '...' : data.description }}</td>
                                    <td>{{ data.buttonText }}</td>
                                    <td>{{ data.buttonLink }}</td>
                                    <td>
                                        <div class="d-flex order-actions">
                                            <a href="javascript:void(0);" @click="editBenefit(data)"
                                                data-bs-toggle="modal" data-bs-target="#editModal" class="edit-button">
                                                <i class="bx bxs-edit text-white"></i>
                                            </a>
                                            <a href="javascript:void(0);" @click="deleteBrand(data.id)"
                                                class="ms-3 delete-button"><i class="bx bxs-trash text-white"></i></a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th>SL</th>
                                    <th>Image</th>
                                    <th>Title</th>
                                    <th>Description</th>
                                    <th>Button Text</th>
                                    <th>Button Link</th>
                                    <th>Action</th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <!-- Description Modal -->
        <div class="modal fade" id="showModal" tabindex="-1" aria-labelledby="showModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="showModalLabel">Description</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <p class="form-control mb-1" type="text" v-html="description"></p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script src='../js/benefits.js'></script>


<style scoped>
@import "../../../../../../../public/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css";
.edit-button {
  background-color: #17a00e;
}
.delete-button {
  background-color: #f41127;
}
.metaModal {
    color: black;
    cursor: pointer;
    text-decoration: none;
    display: block;
}
.metaModal:hover {
    cursor: pointer;
    color: #09f;
}
</style>
