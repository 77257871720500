<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb mb-0 p-0">
            <li class="breadcrumb-item">
              <router-link to="/home">
                <i class="bx bx-home-alt"></i>
              </router-link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">Schedule</li>
          </ol>
        </nav>
        <!-- <div class="ms-auto">
                    <div class="btn-group">
                        <button type="button" class="btn btn-success m-1" data-bs-toggle="modal" data-bs-target="#filterModal" id="filterTable">
                            <i class="bx bx-filter text-white float-left"></i>Filter
                        </button>
                    </div>
        </div>-->
      </div>
      <h6 class="mb-0 text-uppercase">Schedule List</h6>
      <hr />
      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <table id="example" class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>Sl</th>
                  <th>Name</th>
                  <th>Transaction Number</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Role</th>
                  <th>Industry</th>
                  <th>Date</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(data, index) in dataList" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ data.firstName+" "+data.lastName }}</td>
                  <td>{{ data.transactionNumber }}</td>
                  <td>{{ data.email }}</td>
                  <td>{{ data.phone }}</td>
                  <td>{{ data.role }}</td>
                  <td>{{ data.industry }}</td>
                  <td>{{ this.$filters.formatDate(data.createdAt) }}</td>
                  <td>
                    <div class="d-flex order-actions">
                      <a
                        href="javascript:void(0)"
                        @click="deleteData(data.id)"
                        class="ms-3 delete-button"
                      >
                        <i class="bx bxs-trash text-white"></i>
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th>Sl</th>
                  <th>Name</th>
                  <th>Transaction Number</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Role</th>
                  <th>Industry</th>
                  <th>Date</th>
                  <th>Action</th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src='../js/schedule.js'></script>

<style scoped>
@import "../../../../public/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css";
.edit-button {
  background-color: #17a00e;
}
.delete-button {
  background-color: #f41127;
}
.metaModal {
  color: black;
  cursor: pointer;
  text-decoration: none;
  display: block;
}
.metaModal:hover {
  cursor: pointer;
  color: #09f;
}
</style>
