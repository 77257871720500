export default {
    name: "Career",
    data() {
        return {
            dataList: [],
            setURL: this.$serverURL + this.$api.career.careerModelURL
        }
    },
    async created() {
        document.title = "Transaction Bee - Career Model List";
        const data = await this.getDataList(this.setURL);
        this.dataList = data.data;
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
        let style = document.createElement('link');
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = '/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css';
        document.head.appendChild(style);
    },
    methods: {
        deleteData: async function (id) {
            let url = this.setURL + '/' + id;
            this.$swal.showLoading();
            await this.deleteContent(url).then(async (response) => {
                if (response == true) {
                    let teamData = await this.getDataList(this.setURL);
                    this.dataList = teamData.data;
                }
            })
        }
    }
}