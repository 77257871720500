export default {
    name: "AddNewPackage",
    data() {
        return {
            specificationKey: [],
            specificationValue: [],
            specificationArray: {},
            counter: 1,
            cmsData: {},
            singleData: {
                price: "",
                packageType: "",
                packageName: "",
                description: "",
                buttonText: "",
                buttonLink: "",
            }
        }
    },
    async created() {
        document.title = "Transaction Bee - Add New Package";
        this.getData();
    },
    methods: {
        async getData() {
            let url = this.$serverURL + this.$api.home.easyPaymentPackage + '/' + this.$route.params.id;
            let response = await this.getCmsData(url);
            if (response.statusCode == 200) {
                this.cmsData = (response.data.length > 0) ? response.data[0] : {};
                this.singleData = { ...this.cmsData };
                if (Object.keys(this.cmsData).length > 0) {
                    this.counter = 0;
                    let bannerTextData = this.cmsData.features;
                    if (bannerTextData != null) {
                        let dataArray = JSON.parse(bannerTextData);
                        let index = 0;
                        for (const [key, value] of Object.entries(dataArray)) {
                            this.specificationKey[index] = key;
                            this.specificationValue[index] = value;
                            this.specificationArray[this.specificationKey[index]] = this.specificationValue[index];
                            index++;
                            this.counter = this.counter + 1;
                        }
                    }
                }
                else {
                    this.counter = 1;
                }
            }
            else {
                this.cmsData = {};
            }
        },
        addSpecification() {
            let index = this.counter - 1;
            if (!this.specificationKey[index]) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter features!"
                });
                return;
            }
            if (!this.specificationValue[index]) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter icon!"
                });
                return;
            }
            this.counter = this.counter + 1;
        },
        finalSpecification() {
            if(!this.singleData.price) {
                if (this.singleData.price !== 0) {
                    this.showToaster('Please enter price!')
                    return false;
                }
            }
            if (!this.singleData.packageType) {
                this.showToaster('Please select package type!')
                return false;
            }
            if (!this.singleData.packageName) {
                this.showToaster('Please enter package name!')
                return false;
            }
            if (!this.singleData.description) {
                this.showToaster('Please enter description!')
                return false;
            }
            if (!this.singleData.buttonText) {
                this.showToaster('Please enter button text!')
                return false;
            }
            if (!this.singleData.buttonLink) {
                this.showToaster('Please enter button link!')
                return false;
            }
            let index = this.counter - 1;
            if (!this.specificationKey[index]) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter features!"
                });
                return false;
            }
            if (!this.specificationValue[index]) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter icon name!"
                });
                return false;
            }
            return true;
        },
        deleteSpecificationRow(index) {
            delete this.specificationArray[this.specificationKey[index]];
            this.specificationKey.splice(index, 1);
            this.specificationValue.splice(index, 1);
            this.counter = this.counter - 1;
        },
        updateEntity: async function () {
            this.specificationArray = {};
            for (let i = 0; i < this.counter; i++) {
                this.specificationArray[this.specificationKey[i]] = this.specificationValue[i];
            }
            if (!this.finalSpecification()) {
                return;
            }
            // console.log("Final specification array: ", JSON.stringify(this.specificationArray));
            this.$swal.showLoading();
            let data = {
                ...this.singleData,
                features: JSON.stringify(this.specificationArray)
            };
            let url = this.$serverURL + this.$api.home.easyPaymentPackage + '/' + this.$route.params.id;
            this.$swal.showLoading();
            await this.updateData(url, data).then(async (response) => {
                if (response.statusCode == 200) {

                    this.showToaster(response.message, 'success');
                    this.$router.push('/easy-payment-crud');

                }
            });
        }
    }
}