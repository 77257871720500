import { createRouter, createWebHistory } from "vue-router";
import guard from "../guard";
import Login from "../components/auth/template/Login";
import Dashboard from "../components/dashboard/template/Dashboard";
import Error404 from "../components/global/error_404/template/Error404";
import Unauthorized from "../components/global/unauthorized/template/Unauthorized";
import Admin from "../components/user/template/Admin";
import ApprovedUser from "../components/user/template/ApprovedUser";
import UnapprovedUser from "../components/user/template/UnapprovedUser";
import DisabledUser from "../components/user/template/DisabledUser";
import AboutUsCms from "../components/cms/aboutUsCms/template/AboutUsCms"
import ContactUsCms from "../components/cms/contactUsCms/template/ContactUsCms";
import FaqCms from "../components/cms/faqCms/template/FaqCms";
import ScheduleCms from "../components/cms/scheduleCms/template/ScheduleCms";
import CareerCms from "../components/cms/careerCms/template/CareerCms";
import ContactUsDesignations from "../components/cms/contactUsDesignations/template/contactUsDesignations";
import ContactUsOrganization from "../components/cms/contactUsOrganization/template/contactUsOrganization";
import CareerDetailsCms from "../components/cms/careerDetailsCms/template/CareerDetailsCms";
import TermsCms from "../components/cms/termsCms/template/TermsCms";
import PressKitCms from "../components/cms/pressKitCms/template/PressKitCms";
import PartnerPageCms from "../components/cms/partnerPageCms/template/PartnerPageCms";
import HeaderFooterCms from "../components/cms/headerFooterCms/template/HeaderFooterCms";
import PrivacyPolicyCms from "../components/cms/privacy-policy/template/PrivacyPolicyCms";
import SecurityPolicyCms from "../components/cms/security-policy/template/SecurityPolicyCms";
import AboutContent from "../components/aboutContent/template/AboutContent";
import LeadersContent from "../components/leadersContent/template/LeadersContent";
import FaqContent from "../components/faqContent/template/FaqContent";
import Contact from "../components/contact/template/Contact";
import Subscription from "../components/subscription/template/Subscription";
import Schedule from "../components/schedule/template/Schedule";
import PressLink from "../components/press/press-link/template/PressLink";
import PressCoverage from "../components/press/press-coverage/template/PressCoverage";
import CareerTeam from "../components/career-team/template/CareerTeam";
import PressAbout from "../components/press/press-about/template/PressAbout";
import AccelerateContent from "../components/partner/accelerateContent/template/AccelerateContent";
import PremiumPartnersContent from "../components/partner/premiumPartnersContent/template/PremiumPartnersContent";
import CareerLocation from "../components/career-location/template/CareerLocation";
import OfficeAddress from "../components/office-address/template/OfficeAddress";
import AddOfficeAddress from "../components/office-address/template/AddOfficeAddress";
import EditOfficeAddress from "../components/office-address/template/EditOfficeAddress";
import Career from "../components/career/template/Career";
import AddCareer from "../components/career/template/AddCareer";
import EditCareer from "../components/career/template/EditCareer";
import CareerApplicant from "../components/career-applicant/template/CareerApplicant";
import SocialLinks from "../components/cms/social-links/template/SocialLinks";
import ScheduleIndustry from "../components/cms/scheduleIndustry/template/ScheduleIndustry";
import ScheduleRole from "../components/cms/scheduleRole/template/ScheduleRole";
import ScheduleTransactionLimit from "../components/cms/scheduleTransactionLimit/template/ScheduleTransactionLimit";
import SitePageTitleCMS from "../components/cms/site-page-title-cms/template/SitePageTitleCMS";
import SitePageMetaCMS from "../components/cms/site-page-meta-cms/template/SitePageMetaCMS";
import HomePageHeroSection from "../components/cms/homePage/heroSection/template/HeroSection";
import HomePageBrandSection from "../components/cms/homePage/brandSection/template/BrandSection";
import marketBusinessCms from "../components/cms/homePage/marketBusinessCms/template/marketBusinessCms";
import BenefitsAndAdvantagesSectionCMS from "../components/cms/homePage/benefitsAndAdvantages/cms/template/Cms";
import Benefits from "../components/cms/homePage/benefitsAndAdvantages/benefits/template/Benefits";
import HowItWorksCMS from "../components/cms/homePage/howItWorks/cms/template/Cms";
import HowItWorksCRUD from "../components/cms/homePage/howItWorks/crud/template/Crud";
import CustomerReviewsCMS from "../components/cms/homePage/customerReviews/cms/template/Cms";
import CustomerReviewsCRUD from "../components/cms/homePage/customerReviews/crud/template/Crud";
import ImproveSectionCMS from "../components/cms/homePage/improveSection/template/ImproveSection";
import ApplicationIntegrationCMS from "../components/cms/homePage/applicationsIntegration/cms/template/Cms";
import ApplicationIntegration from "../components/cms/homePage/applicationsIntegration/applications/template/Applications";
import NewsletterCMS from "../components/cms/newsletterCms/template/Newsletter";
import EasyPaymentCMS from "../components/cms/homePage/easyPayment/cms/template/Cms";
import EasyPaymentCRUD from "../components/cms/homePage/easyPayment/crud/template/Crud";
import AddNewPackage from "../components/cms/homePage/easyPayment/crud/template/AddNewPackage";
import EditNewPackage from "../components/cms/homePage/easyPayment/crud/template/EditPackage";
import WhatsNextSectionCMS from "../components/cms/homePage/whatsNext/template/WhatsNextSection";
import SigninCMS from "../components/cms/sign-in/template/SignInCms";
import SignupCMS from "../components/cms/sign-up/template/SignUpCms";
import AuthCommonData from "../components/auth-common-data/template/AuthCommonData";
import ColorCodeTransactionStatusCMS from "../components/cms/color-code-transaction-status-cms/template/ColorCodeTransactionStatusCMS";

const routes = [
	/* Auth Routes */
	{ path: "/admin-login", name: "Login", component: Login },
	{ path: "/", redirect: { name: 'Home' } },
	{ path: "/home", name: "Home", component: Dashboard, beforeEnter: guard },

	// User
	{ path: '/admin', name: "Admin", component: Admin, beforeEnter: guard },
	{ path: '/approved-user', name: "ApprovedUser", component: ApprovedUser, beforeEnter: guard },
	{ path: '/unapproved-user', name: "UnapprovedUser", component: UnapprovedUser, beforeEnter: guard },
	{ path: '/rejected-user', name: "DisabledUser", component: DisabledUser, beforeEnter: guard },

	/* Error and Unauthorized routes */
	{ path: "/404-not-found", name: "404 Not Found", component: Error404, beforeEnter: guard },
	{ path: "/unauthorized", name: "Unauthorized", component: Unauthorized, beforeEnter: guard },
	{ path: '/:pathMatch(.*)*', component: Error404, beforeEnter: guard },


	/* Career Routes */
	{ path: "/career-team", name: "CareerTeam", component: CareerTeam, beforeEnter: guard },
	{ path: "/career-location", name: "CareerLocation", component: CareerLocation, beforeEnter: guard },
	{ path: "/career-applicant", name: "CareerApplicant", component: CareerApplicant, beforeEnter: guard },
	{ path: "/career", name: "Career", component: Career, beforeEnter: guard },
	{ path: "/add-career", name: "AddCareer", component: AddCareer, beforeEnter: guard },
	{ path: "/edit-career/:id", name: "EditCareer", component: EditCareer, beforeEnter: guard },

	// Subscription
	{ path: "/subscription", name: "Subscription", component: Subscription, beforeEnter: guard },


	/* Contact Routes */
	{ path: '/contact', name: "Contact", component: Contact, beforeEnter: guard },

	/* Office Address Routes */
	{ path: '/office-address', name: "OfficeAddress", component: OfficeAddress, beforeEnter: guard },
	{ path: "/edit-office-address/:id", name: "EditOfficeAddress", component: EditOfficeAddress, beforeEnter: guard },
	{ path: '/add-office-address', name: "AddOfficeAddress", component: AddOfficeAddress, beforeEnter: guard },

	// Partner
	{ path: '/accelerate-content', name: "AccelerateContent", component: AccelerateContent, beforeEnter: guard },
	{ path: '/premium-partners-content', name: "PremiumPartnersContent", component: PremiumPartnersContent, beforeEnter: guard },


	// CMS
	{ path: "/site-page-title-cms", name: "SitePageTitleCMS", component: SitePageTitleCMS, beforeEnter: guard },
	{ path: "/site-page-meta-cms", name: "SitePageMetaCMS", component: SitePageMetaCMS, beforeEnter: guard },
	{ path: '/about-us-page-cms', name: "AboutUsCms", component: AboutUsCms, beforeEnter: guard },
	{ path: '/contact-us-page-cms', name: "ContactUsCms", component: ContactUsCms, beforeEnter: guard },
	{ path: '/contact-us-designations', name: "ContactUsDesignations", component: ContactUsDesignations, beforeEnter: guard },
	{ path: '/contact-us-page-organization', name: "ContactUsOrganization", component: ContactUsOrganization, beforeEnter: guard },
	{ path: '/faq-page-cms', name: "FaqCms", component: FaqCms, beforeEnter: guard },
	{ path: '/career-page-cms', name: "CareerCms", component: CareerCms, beforeEnter: guard },
	{ path: '/career-details-page-cms', name: "CareerDetailsCms", component: CareerDetailsCms, beforeEnter: guard },
	{ path: '/terms-page-cms', name: "TermsCms", component: TermsCms, beforeEnter: guard },
	{ path: '/press-kit-page-cms', name: "PressKitCms", component: PressKitCms, beforeEnter: guard },
	{ path: '/partner-page-cms', name: "PartnerPageCms", component: PartnerPageCms, beforeEnter: guard },
	{ path: '/header-footer-cms', name: "HeaderFooterCms", component: HeaderFooterCms, beforeEnter: guard },
	{ path: '/press-about-content-cms', name: "PressAbout", component: PressAbout, beforeEnter: guard },
	{ path: "/social-links", name: "SocialLinks", component: SocialLinks, beforeEnter: guard },
	{ path: "/color-code-transaction-status", name: "ColorCodeTransactionStatusCMS", component: ColorCodeTransactionStatusCMS, beforeEnter: guard },
	// schedule cms
	{ path: '/schedule-page-cms', name: "ScheduleCms", component: ScheduleCms, beforeEnter: guard },
	{ path: '/schedule-industry', name: "ScheduleIndustry", component: ScheduleIndustry, beforeEnter: guard },
	{ path: '/schedule-transaction-limit', name: "ScheduleTransactionLimit", component: ScheduleTransactionLimit, beforeEnter: guard },
	{ path: '/schedule-role', name: "scheduleRole", component: ScheduleRole, beforeEnter: guard },

	// Content
	{ path: '/about-page-content', name: "AboutContent", component: AboutContent, beforeEnter: guard },
	{ path: '/leaders-content', name: "LeadersContent", component: LeadersContent, beforeEnter: guard },
	{ path: '/faq-content', name: "FaqContent", component: FaqContent, beforeEnter: guard },
	{ path: '/schedule', name: "Schedule", component: Schedule, beforeEnter: guard },
	{ path: '/press-link', name: "PressLink", component: PressLink, beforeEnter: guard },
	{ path: '/press-coverage', name: "PressCoverage", component: PressCoverage, beforeEnter: guard },

	// Policies
	{ path: '/privacy-policy-page-cms', name: "PrivacyPolicyCms", component: PrivacyPolicyCms, beforeEnter: guard },
	{ path: '/security-policy-page-cms', name: "SecurityPolicyCms", component: SecurityPolicyCms, beforeEnter: guard },

	// Home Page
	{ path: '/home-page-hero-section-cms', name: "HomePageHeroSection", component: HomePageHeroSection, beforeEnter: guard },
	{ path: '/home-page-brand-section-cms', name: "HomePageBrandSection", component: HomePageBrandSection, beforeEnter: guard },
	{ path: '/home-page-market-business-cms', name: "marketBusinessCms", component: marketBusinessCms, beforeEnter: guard },
	{ path: '/home-page-benefits-and-advantages-section-cms', name: "BenefitsAndAdvantagesSectionCMS", component: BenefitsAndAdvantagesSectionCMS, beforeEnter: guard },
	{ path: '/home-page-benefits', name: "Benefits", component: Benefits, beforeEnter: guard },
	{ path: '/how-it-works-cms', name: "HowItWorksCMS", component: HowItWorksCMS, beforeEnter: guard },
	{ path: '/how-it-works-crud', name: "HowItWorksCRUD", component: HowItWorksCRUD, beforeEnter: guard },
	{ path: '/customer-reviews-cms', name: "CustomerReviewsCMS", component: CustomerReviewsCMS, beforeEnter: guard },
	{ path: '/customer-reviews-crud', name: "CustomerReviewsCRUD", component: CustomerReviewsCRUD, beforeEnter: guard },
	{ path: '/home-page-improve-section-cms', name: "ImproveSectionCMS", component: ImproveSectionCMS, beforeEnter: guard },
	{ path: '/application-integration-cms', name: "ApplicationIntegrationCMS", component: ApplicationIntegrationCMS, beforeEnter: guard },
	{ path: '/application-integration', name: "ApplicationIntegration", component: ApplicationIntegration, beforeEnter: guard },
	{ path: '/easy-payment-cms', name: "EasyPaymentCMS", component: EasyPaymentCMS, beforeEnter: guard },
	{ path: '/easy-payment-crud', name: "EasyPaymentCRUD", component: EasyPaymentCRUD, beforeEnter: guard },
	{ path: '/add-new-package', name: "AddNewPackage", component: AddNewPackage, beforeEnter: guard },
	{ path: '/edit-package/:id', name: "EditNewPackage", component: EditNewPackage, beforeEnter: guard },
	{ path: '/whats-next-section-cms', name: "WhatsNextSectionCMS", component: WhatsNextSectionCMS, beforeEnter: guard },

	// Newsletter
	{ path: '/newsletter-cms', name: "NewsletterCMS", component: NewsletterCMS, beforeEnter: guard },

	// Auth CMS
	{ path: '/signin-cms', name: "SigninCMS", component: SigninCMS, beforeEnter: guard },
	{ path: '/signup-cms', name: "SignupCMS", component: SignupCMS, beforeEnter: guard },
	{ path: '/auth-common-data', name: "AuthCommonData", component: AuthCommonData, beforeEnter: guard },
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
});

export default router;
