// import { authHeader } from '../../../auth';

export default {
    data() {
        return {
            requestUrl: this.$serverURL + this.$api.content.schedule,
            dataList: [],
        }
    },
    async created() {
        document.title = "Transaction Bee - Schedule Content";
        await this.getData();
        this.loadScripts();//from mixin
    },
    methods: {
        async getData() {
            await this.getDataList(this.requestUrl).then(response => {
                if (response.statusCode == 200) {
                    this.dataList = response.data;
                }
            });
        },


        async deleteData(id) {
            const url = this.requestUrl + '/' + id
            this.$swal.showLoading();
            await this.deleteContent(url).then(async (response) => {
                if (response == true) {
                    await this.getData();
                }
            })
        }
    }
}