export default {
    name: "HomePageHeroSection",
    data() {
        return {
            url: this.$serverURL + this.$api.home.heroSectionCms,
            cmsData: {},
            previewImage: "",
        }
    },
    async created() {
        document.title = "Transaction Bee - Home Page Hero Section CMS";
        this.getData();

    },
    methods: {
        async getData() {
            let response = await this.getCmsData(this.url);
            if (response.statusCode == 200) {
                this.cmsData = (response.data.length > 0) ? response.data[0] : {};
            }
            else {
                this.cmsData = {};
            }
        },
        async updateCms() {
            try {
                const formData = new FormData();
                formData.append("title", this.cmsData.title);
                formData.append("subTitle", this.cmsData.subTitle);
                formData.append("description", this.cmsData.description);
                formData.append("buttonOneText", this.cmsData.buttonOneText);
                formData.append("buttonOneLink", this.cmsData.buttonOneLink);
                formData.append("buttonTwoText", this.cmsData.buttonTwoText);
                formData.append("buttonTwoLink", this.cmsData.buttonTwoLink);
                formData.append("overviewImage", this.cmsData.overviewImage);
                this.$swal.showLoading();
                let response = await this.createUpdateCMS(this.url, formData);
                if (response.statusCode == 200 || response.statusCode == 201) {

                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
                await this.getData();
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            } finally {
                this.$swal.hideLoading();
            }
        },
        handleUploadImage(imageKey, ref, event) {
            this.cmsData[imageKey] = event.target.files[0];
            let input = this.$refs[ref];
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewImage = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
    }
}