import LvColorpicker from 'lightvue/color-picker';

export default {
    components: {
        LvColorpicker
    },
    data() {
        return {
            url: this.$serverURL + this.$api.cms.colorCodeTransactionStatusCMS,
            cmsData: {},
            dataLoaded: false,
            colors: [
                "#E91E63",
                "#F44336",
                "#9C27B0",
                "#673AB7",
                "#3F51B5",
                "#2196F3",
                "#03A9F4",
                "#00BCD4",
                "#009688",
                "#4CAF50",
                "#8BC34A",
                "#CDDC39",
                "#FFEB3B",
                "#FFC107",
                "#FF9800",
                "#795548"
            ]
        }
    },
    async created() {
        document.title = "Transaction Bee - Color Code Transaction Status CMS";
        await this.getData().then(() => {
            this.dataLoaded = true;
        });
    },
    methods: {
        async getData() {
            let response = await this.getCmsData(this.url);
            if (response.statusCode == 200) {
                this.cmsData = (response.data.length > 0) ? response.data[0] : {};
            }
            else {
                this.cmsData = {};
            }
        },
        async updateCms() {
            try {
                let data = {
                    'pending': this.cmsData.pending ? this.cmsData.pending : '#ffffff',
                    'offerAccepted': this.cmsData.offerAccepted ? this.cmsData.offerAccepted : '#ffffff',
                    'inspection': this.cmsData.inspection ? this.cmsData.inspection : '#ffffff',
                    'apraisal': this.cmsData.apraisal ? this.cmsData.apraisal : '#ffffff',
                    'clearToClose': this.cmsData.clearToClose ? this.cmsData.clearToClose : '#ffffff',
                    'closing': this.cmsData.closing ? this.cmsData.closing : '#ffffff',
                    'closed': this.cmsData.closed ? this.cmsData.closed : '#ffffff'
                };
                this.$swal.showLoading();
                let response = await this.createUpdateCMS(this.url, data);
                if (response.statusCode == 200 || response.statusCode == 201) {
                    
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
                await this.getData();
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            } finally {
                this.$swal.hideLoading();
            }
        }
    }
}


