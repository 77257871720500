import { authHeader } from '../../../auth';

export default {
    name: "EditOfficeAddress",
    data() {
        return {
            officeAddressId: '',
            addressData: {},
            title: '',
            editData: [],
            setURL: this.$serverURL + this.$api.officeAddress.requestURL
        }
    },
    mounted() {
        if(this.$route.params.id != undefined) {
            this.officeAddressId = this.$route.params.id;
        }
        this.getOfficeAddressData(this.officeAddressId);
    },
    async created() {
        document.title = "Transaction Bee - Office Address";
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script =>
        {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
        let style = document.createElement('link');
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = '/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css';
        document.head.appendChild(style);
    },
    methods: {
        getOfficeAddressData: async function(id) {
            let config = {
                method: "GET",
                url: this.setURL + '/' + id,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.editData = response.data.data[0];
                    this.title = this.editData.title;
                    this.addressData = {
                        address: this.editData.streetAddress,
                        city: this.editData.city,
                        state: this.editData.state,
                        postalCode: this.editData.postalCode
                    };
                }
            }).catch(error => {
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
        setAddress(locationData) {
            let location = locationData.formatted_address;
            let addressComponent = locationData.address_components;
            let locationSplit = location.split(',');
            if (locationSplit[0].trim() != locationData.name) {
                location = locationData.name + ', ' + location;
            }
            this.addressData.address = locationData.name;

            this.addressData.city = "";
            this.addressData.postalCode = "";
            this.addressData.state = "";

            for (let i = 0; i < addressComponent.length; i++) {
                if (addressComponent[i].types[0] == "locality") {
                    this.addressData.city = addressComponent[i].long_name;
                } else if (addressComponent[i].types[0] == "postal_code") {
                    this.addressData.postalCode = addressComponent[i].long_name;
                } else if (addressComponent[i].types[0] == "administrative_area_level_1") {
                    this.addressData.state = addressComponent[i].short_name;
                }
            }
        },
        checkValidation: function () {
            if (!this.title) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please provide address title.",
                });
                return false;
            } else if (!this.addressData.address) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please provide office address.",
                });
                return false;
            } else if (!this.addressData.city) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please provide city.",
                });
                return false;
            } else if (!this.addressData.state) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please provide state.",
                });
                return false;
            } else if (!this.addressData.postalCode) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please provide address postal code.",
                });
                return false;
            } else {
                return true;
            }
        },
        save: async function() {
            if (this.checkValidation()) {
                const data = {
                    title: this.title,
                    streetAddress: this.addressData.address,
                    city: this.addressData.city,
                    state: this.addressData.state,
                    postalCode: this.addressData.postalCode
                }
                let url = this.setURL + '/' + this.officeAddressId;
                this.$swal.showLoading();
                let response = await this.updateData(url, data);
                if (response.statusCode == 200) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) =>
                        {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
                this.editData = [];
                this.addressData = {};
                this.$router.push("/office-address");
            }

        }
    }
}