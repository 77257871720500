<!-- eslint-disable no-mixed-spaces-and-tabs -->
<template>
	<div class="page-wrapper">
	  <div class="page-content">
		<div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
		  <div class="breadcrumb-title pe-3">Whats Next Section CMS</div>
		  <div class="ps-3">
			<nav aria-label="breadcrumb">
			  <ol class="breadcrumb mb-0 p-0">
				<li class="breadcrumb-item">
				  <router-link to="/dashboard">
					<i class="bx bx-home-alt"></i>
				  </router-link>
				</li>
				<li class="breadcrumb-item active" aria-current="page">Whats Next Section CMS</li>
			  </ol>
			</nav>
		  </div>
		</div>
		<form @submit.prevent="updateCms()">
		  <div class="card">
			<div class="card-body">
			  <h5 class="card-title">Whats Next Section CMS</h5>
			  <hr />
			  <div class="form-body mt-4">
				<div class="row">
				  <div class="col-lg-8 mb-3">
					<div class="border border-3 p-4 rounded">
					  <div class="row">
						<div class="col-lg-12 mb-3">
							<div class="col-12 mb-3">
								<label class="form-label">
									Section One Image: [Preferred Image Size: 1132X695, Max Upload Limit:
									1MB]
								</label>
								<input
									type="file"
									class="form-control"
									id="inputPageHeaderTitle"
									placeholder="Enter page title"
									ref="sectionOneImage"
									accept="image/*"
									@change="handleUploadImage('sectionOneImage','image','sectionOneImage', $event)"
								/>
							</div>
							<div class="mb-3">
								<img
									style="position: relative;"
									v-if="preview.image"
									:src="preview.image"
									height="100"
								/>
								<img
									style="position: relative;"
									v-else-if="cmsData.sectionOneImage"
									:src="cmsData.sectionOneImage"
									height="100"
								/>
							</div>
						</div>
						<div class="col-12 mb-3">
						  <label class="form-label">Section One Title:</label>
						  <input
							type="text"
							v-model="cmsData.sectionOnetitle"
							class="form-control"
							id="inputPageHeaderTitle"
							placeholder="Enter section one title"
						  />
						</div>
						<div class="col-12 mb-3">
						  <label class="form-label">Section One Subtitle:</label>
						  <input
							type="text"
							v-model="cmsData.sectionOneSubtitle"
							class="form-control"
							id="inputPageHeaderTitle"
							placeholder="Enter section one sub title"
						  />
						</div>
						<div class="col-12 mb-3">
						  <label class="form-label">Section One Description:</label>
						  <ckeditor :editor="editor" v-model="cmsData.sectionOneDescription" :config="editorConfig"></ckeditor>
						</div>
						<div class="col-12 mb-3">
						  <label class="form-label">Section One Button Text:</label>
						  <input
							type="text"
							v-model="cmsData.sectionOneButtonText"
							class="form-control"
							id="inputPageHeaderTitle"
							placeholder="Enter button text"
						  />
						</div>
						<div class="col-12 mb-3">
						  <label class="form-label">Section One Button Link:</label>
						  <input
							type="text"
							v-model="cmsData.sectionOneButtonLink"
							class="form-control"
							id="inputPageHeaderTitle"
							placeholder="Enter one button text"
						  />
						</div>
					  </div>
					</div>
				  </div>
				  <div class="col-lg-8 mb-3">
					<div class="border border-3 p-4 rounded">
					  <div class="row">
						<div class="col-12 mb-3">
						  <label class="form-label">
							Section Two Image: [Preferred Image Size: 1132X695, Max Upload Limit:
							1MB]
						  </label>
						  <input
							type="file"
							class="form-control"
							id="inputPageHeaderTitle"
							placeholder="Enter page title"
							ref="sectionTwoImage"
							accept="image/*"
							@change="handleUploadImage('sectionTwoImage','mobileImage','sectionTwoImage', $event)"
						  />
						</div>
						<div class="mb-3">
						  <img
							style="position: relative;"
							v-if="preview.mobileImage"
							:src="preview.mobileImage"
							height="100"
						  />
						  <img
							style="position: relative;"
							v-else-if="cmsData.sectionTwoImage"
							:src="cmsData.sectionTwoImage"
							height="100"
						  />
						</div>
						<div class="col-12 mb-3">
						  <label class="form-label">Section Two Title:</label>
						  <input
							type="text"
							v-model="cmsData.sectionTwotitle"
							class="form-control"
							id="inputPageHeaderTitle"
							placeholder="Enter two title"
						  />
						</div>
						<div class="col-12 mb-3">
						  <label class="form-label">Section Two Subtitle:</label>
						  <input
							type="text"
							v-model="cmsData.sectionTwoSubtitle"
							class="form-control"
							id="inputPageHeaderTitle"
							placeholder="Enter two sub title"
						  />
						</div>
						<div class="col-12 mb-3">
						  <label class="form-label">Section Two Description:</label>
						  <ckeditor :editor="editor" v-model="cmsData.sectionTwoDescription" :config="editorConfig"></ckeditor>
						</div>
						<div class="col-12 mb-3">
						  <label class="form-label">Section Two Button Text:</label>
						  <input
							type="text"
							v-model="cmsData.sectionTwoButtonText"
							class="form-control"
							id="inputPageHeaderTitle"
							placeholder="Enter section two button text"
						  />
						</div>
						<div class="col-12 mb-3">
						  <label class="form-label">Section Two Button Link:</label>
						  <input
							type="text"
							v-model="cmsData.sectionTwoButtonLink"
							class="form-control"
							id="inputPageHeaderTitle"
							placeholder="Enter section two button link"
						  />
						</div>
					  </div>
					</div>
				  </div>
				  <div class="d-grid col-12 col-lg-8">
					<button type="submit" class="btn btn-primary">Save</button>
				  </div>
				</div>
			  </div>
			</div>
		  </div>
		</form>
	  </div>
	</div>
  </template>
  
  <script src="../js/whats-next-section-cms.js"></script>