<template>
	<div class="page-wrapper">
		<div class="page-content">
			<div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
				<div class="breadcrumb-title pe-3">Market Your Business CMS</div>
				<div class="ps-3">
					<nav aria-label="breadcrumb">
						<ol class="breadcrumb mb-0 p-0">
							<li class="breadcrumb-item">
								<router-link to="/dashboard">
									<i class="bx bx-home-alt"></i>
								</router-link>
							</li>
							<li class="breadcrumb-item active" aria-current="page">Market Your Business CMS</li>
						</ol>
					</nav>
				</div>
			</div>
			<form @submit.prevent="updateCms()">
				<div class="card">
					<div class="card-body">
						<h5 class="card-title">Market Your Business CMS</h5>
						<hr />
						<div class="form-body mt-4">
							<div class="row">
								<div class="col-lg-6 mb-3">
									<div class="border border-3 p-4 rounded">
										<div class="row">
											<div class="col-12 mb-3">
												<label class="form-label">Title:</label>
												<input type="text" v-model="cmsData.title" class="form-control" placeholder="Enter title" />
											</div>
											<div class="col-12 mb-3">
												<label class="form-label">Sub Title:</label>
												<input type="text" v-model="cmsData.subTitle" class="form-control" placeholder="Enter subtitle" />
											</div>
											<div class="col-12 mb-3">
												<label class="form-label">Description:</label>
												<input type="text" v-model="cmsData.description" class="form-control" placeholder="Enter description" />
											</div>
											<div class="col-lg-12 mb-3">
												<div class="row">
													<div class="col-12 mb-3">
														<label class="form-label">
														Image: [Preferred Image Size: 30X30, Max Upload Limit:1MB]
														</label>
														<input
															type="file"
															class="form-control"
															ref="image"
															accept="image/*"
															@change="handleUploadImage('image','image', $event)"
														/>
													</div>
													<div class="mb-3">
														<img
															style="position: relative;"
															v-if="previewImage"
															:src="previewImage"
															height="50"
														/>
														<img
															style="position: relative;"
															v-else-if="cmsData.image"
															:src="cmsData.image"
															height="50"
														/>
													</div>
												</div>
											</div>
											<div class="col-12 mb-3">
												<label class="form-label">Button Text:</label>
												<input type="text" v-model="cmsData.buttonText" class="form-control" placeholder="Enter button text" />
											</div>
											<div class="col-12 mb-3">
												<label class="form-label">Button Link:</label>
												<input type="text" v-model="cmsData.buttonLink" class="form-control" placeholder="Enter button link" />
											</div>
										</div>
									</div>
								</div>
								<div class="col-lg-6">
                                    <div class="border border-3 p-4 rounded mb-3">
                                        <div class="row mb-3" v-for="index in counter" :key="index">
                                            <div class="col-sm-6">
                                                <label class="form-label">Features:</label>
                                                <input type="text" class="form-control" v-model="specificationKey[index - 1]"/>
                                            </div>
                                            <div class="col-sm-6">
                                                <label class="form-label">Icon:</label>
                                                <div class="d-flex gap-2">
                                                    <input type="text" class="form-control" v-model="specificationValue[index - 1]"/>
                                                    <button v-if="counter > 1" @click="deleteSpecificationRow(index - 1)" type="button" class="btn btn-danger">X</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mb-3">
                                            <button type="button" @click="addSpecification()" class="btn btn-primary">Add More</button>
                                        </div>
                                    </div>
									<div class="d-grid col-12 col-lg-12">
										<button type="submit" class="btn btn-primary">Save</button>
									</div>
                                </div>
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>
	</div>
</template>

<script src="../js/market-business-cms.js"></script>