<template>
	<div class="page-wrapper">
		<div class="page-content">
			<div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
				<div class="breadcrumb-title pe-3">About Us Page CMS</div>
				<div class="ps-3">
					<nav aria-label="breadcrumb">
						<ol class="breadcrumb mb-0 p-0">
							<li class="breadcrumb-item">
								<router-link to="/dashboard">
									<i class="bx bx-home-alt"></i>
								</router-link>
							</li>
							<li class="breadcrumb-item active" aria-current="page">About Us Page CMS</li>
						</ol>
					</nav>
				</div>
			</div>
			<form @submit.prevent="updateCms()">
				<div class="card">
					<div class="card-body">
						<h5 class="card-title">About Us Page CMS</h5>
						<hr />
						<div class="form-body mt-4">
							<div class="row">
								<div class="col-lg-8 mb-3">
									<div class="border border-3 p-4 rounded">
										<div class="row">
											<div class="col-12 mb-3">
												<label class="form-label">Page Title:</label>
												<input type="text" v-model="cmsData.pageTitle" class="form-control"
													id="inputPageHeaderTitle" placeholder="Enter page title" />
											</div>
											<div class="col-12 mb-3">
												<label class="form-label">Page Sub Title:</label>
												<input type="text" v-model="cmsData.pageSubTitle" class="form-control"
													id="inputPageHeaderTitle" placeholder="Enter page sub title" />
											</div>
											<div class="col-12 mb-3">
												<label class="form-label">Section One Title:</label>
												<input type="text" v-model="cmsData.sectionOneTitle" class="form-control"
													id="inputPageHeaderTitle" placeholder="Enter section one title" />
											</div>
											<div class="col-12 mb-3">
												<label class="form-label">Section One Subtitle:</label>
												<input type="text" v-model="cmsData.sectionOneSubTitle" class="form-control"
													id="inputPageHeaderTitle" placeholder="Enter section one sub title" />
											</div>
											<div class="col-12 mb-3">
												<label class="form-label">Section Two Title:</label>
												<input type="text" v-model="cmsData.sectionTwoTitle" class="form-control"
													id="inputPageHeaderTitle" placeholder="Enter section two title" />
											</div>
											<div class="col-12 mb-3">
												<div class="row">
													<div class="col-12">
														<label class="form-label">
														Video :
														<strong>[Max Upload Limit: 10MB]</strong>
														</label>
													</div>
													<div class="col-12">
														<video width="320" height="240" v-if="preview.videoLink" controls>
														<source :src="preview.videoLink" type="video/mp4">
														Your browser does not support the video tag.
														</video>
														<video width="320" height="240" v-else-if="cmsData.videoLink" controls>
														<source :src="cmsData.videoLink" type="video/mp4">
														Your browser does not support the video tag.
														</video>
													</div>
												</div>
												<input
												class="form-control mt-3"
												type="file"
												ref="videoLink"
												accept="videoLink/*"
												@change="handleUploadVideo('videoLink', 'videoLink', 'videoLink', $event)"
												/>
											</div>
											<div class="col-12 mb-3">
												<label class="form-label">Button Text:</label>
												<input type="text" v-model="cmsData.buttonText" class="form-control"
													id="inputPageHeaderTitle" placeholder="Enter button text" />
											</div>
											<!-- <div class="col-12 mb-3">
												<label class="form-label">Button Link:</label>
												<input type="text" v-model="cmsData.buttonLink" class="form-control"
													id="inputPageHeaderTitle" placeholder="Enter button link" />
											</div> -->
										</div>
									</div>
								</div>
								<div class="col-lg-4 mb-3">
									<div class="border border-3 p-4 rounded">
										<div class="row">
											<div class="col-12 mb-3">
												<label class="form-label">Hero Background Image: [Preferred Image Size: 30X30, Max
													Upload Limit:
													1MB]</label>
												<input type="file" class="form-control" id="inputPageHeaderTitle"
													placeholder="Enter page title" ref="heroBackgroundImg" accept="image/*, .svg"
													@change="handleUploadImage('heroBackgroundImage', 'heroBackgroundImg', $event)" />
											</div>
											<div class="mb-3 bg-black">
												<img style="position: relative;" v-if="previewImage" :src="previewImage"
													height="100" />
												<img style="position: relative;" v-else-if="cmsData.heroBackgroundImage"
													:src="cmsData.heroBackgroundImage" height="100" />
											</div>
										</div>
									</div>

									<div class="border border-3 p-4 mt-3 rounded">
										<div class="row">
											<div class="col-12 mb-3">
												<label class="form-label">Leader Background Image: [Preferred Image Size: 30X30, Max
													Upload Limit:
													1MB]</label>
												<input type="file" class="form-control" id="inputPageHeaderTitle"
													placeholder="Enter page title" ref="leaderBackgroundImg" accept="image/*, .svg"
													@change="handleUploadImage2('leaderBackgroundImage', 'leaderBackgroundImg', $event)" />
											</div>
											<div class="mb-3">
												<img style="position: relative;" v-if="previewImage2" :src="previewImage2"
													height="100" />
												<img style="position: relative;" v-else-if="cmsData.leaderBackgroundImage"
													:src="cmsData.leaderBackgroundImage" height="100" />
											</div>
										</div>
									</div>
								</div>
								<div class="d-grid col-12 col-lg-8">
									<button type="submit" class="btn btn-primary">Save</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>
	</div>
</template>

<script src="../js/about-us-cms.js"></script>