<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div class="breadcrumb-title pe-3">Terms Page CMS</div>
        <div class="ps-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
              <li class="breadcrumb-item">
                <router-link to="/dashboard">
                  <i class="bx bx-home-alt"></i>
                </router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">Terms Page CMS</li>
            </ol>
          </nav>
        </div>
      </div>
      <form @submit.prevent="updateCms()">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Terms Page CMS</h5>
            <hr />
            <div class="form-body mt-4">
              <div class="row">
                <div class="col-lg-8 mb-3">
                  <div class="border border-3 p-4 rounded">
                    <div class="row">
                      <div class="col-12 mb-3">
                        <label class="form-label">Page Title:</label>
                        <input
                          type="text"
                          v-model="cmsData.pageTitle"
                          class="form-control"
                          id="inputPageHeaderTitle"
                          placeholder="Enter page title"
                        />
                      </div>
                      <div class="col-12 mb-3">
                        <label class="form-label">Terms of Use Title:</label>
                        <input
                          type="text"
                          v-model="cmsData.termsofUseTitle"
                          class="form-control"
                          id="inputPageHeaderTitle"
                          placeholder="Enter terms of use title"
                        />
                      </div>
                      <div class="col-12 mb-3">
                        <label class="form-label">Get Application Title:</label>
                        <input
                          type="text"
                          v-model="cmsData.getApplicationTitle"
                          class="form-control"
                          id="inputPageHeaderTitle"
                          placeholder="Enter get application title"
                        />
                      </div>
                      <div class="col-12 mb-3">
                        <label class="form-label">Get Application Link:</label>
                        <input
                          type="text"
                          v-model="cmsData.getApplicationLink"
                          class="form-control"
                          id="inputPageHeaderTitle"
                          placeholder="Enter get application Link "
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 mb-3">
                  <div class="border border-3 p-4 rounded">
                    <div class="row">
                      <div class="col-12 mb-3">
                        <label class="form-label">
                          Terms of Use Icon: [Preferred Image Size: 64X64, Max Upload Limit:
                          1MB]
                        </label>
                        <input
                          type="file"
                          class="form-control"
                          id="inputPageHeaderTitle"
                          placeholder="Enter page title"
                          ref="icon"
                          accept="image/*"
                          @change="handleUploadImage('termsofUseIcon', 'image1', 'icon', $event)"
                        />
                      </div>
                      <div class="mb-3">
                        <img
                          style="position: relative;"
                          v-if="preview.image1"
                          :src="preview.image1"
                          height="100"
                        />
                        <img
                          style="position: relative;"
                          v-else-if="cmsData.termsofUseIcon"
                          :src="cmsData.termsofUseIcon"
                          height="100"
                        />
                      </div>
                      <div class="col-12 mb-3">
                        <label class="form-label">Terms of Use File:</label>
                        <input
                          type="file"
                          class="form-control"
                          id="inputPageHeaderTitle"
                          accept=".pdf"
                          @change="handleUploadImage('file', '', '', $event)"
                        />
                      </div>
                      <div class="mb-3" v-if="cmsData.file">
                        <span style="font-weight:600">Current File: <a :href="cmsData.file" download><button type="button" class="btn btn-sm btn-primary"><i class="bx bx-download"></i>Download</button></a></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-grid col-12 col-lg-8">
                  <button type="submit" class="btn btn-primary">Save</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script src="../js/terms-cms.js"></script>