<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb mb-0 p-0">
            <li class="breadcrumb-item">
              <router-link to="/home">
                <i class="bx bx-home-alt"></i>
              </router-link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">User List</li>
          </ol>
        </nav>
        <div class="ms-auto">
          <div class="btn-group">
            <button
              type="button"
              class="btn btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#addModal"
            >
              <i class="bx bxs-plus-square"></i>Add New User
            </button>
          </div>
          <!-- Add Modal -->
          <div
            class="modal fade"
            id="addModal"
            tabindex="-1"
            aria-labelledby="addModalLabel"
            aria-hidden="true"
          >
            <form>
              <div class="modal-dialog modal-lg">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="addModalLabel">
                      <i class="bx bxs-plus-square"></i> Add New User
                    </h5>
                    <button
                      type="button"
                      id="addModalCloseButton"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="row ms-1">
                    <div class="modal-body col-md-6">
                      <label class="form-label">
                        First Name:
                        <span style="color: red; font-size: 18px">*</span>
                      </label>
                      <input
                        class="form-control mb-1"
                        type="text"
                        v-model="firstName"
                        placeholder="Enter first name"
                      />
                    </div>
                    <div class="modal-body col-md-6">
                      <label class="form-label">
                        Last Name:
                        <span style="color: red; font-size: 18px">*</span>
                      </label>
                      <input
                        class="form-control mb-1"
                        type="text"
                        v-model="lastName"
                        placeholder="Enter last name"
                      />
                    </div>
                  </div>
                  <div class="row ms-1">
                    <div class="modal-body col-md-6">
                      <label class="form-label">
                        Email:
                        <span style="color: red; font-size: 18px">*</span>
                      </label>
                      <input
                        class="form-control mb-1"
                        type="email"
                        v-model="email"
                        placeholder="Enter email"
                      />
                    </div>
                    <div class="modal-body col-md-6">
                      <label class="form-label">
                        Phone No:
                        <span style="color: red; font-size: 18px">*</span>
                      </label>
                      <input
                        class="form-control mb-1"
                        type="text"
                        v-model="phone"
                        placeholder="Enter phone number"
                      />
                    </div>
                  </div>
                  <div class="row ms-1">
                    <div class="modal-body col-md-6">
                      <label class="form-label">
                        Password:
                        <span style="color: red; font-size: 18px">*</span>
                      </label>
                      <div class="input-group" id="show_hide_password">
                        <input
                          :type="inputTypePassword"
                          v-model="password"
                          class="form-control border-end-0"
                          id="inputPassword"
                          placeholder="Enter password"
                        />
                        <a
                          href="javascript:void(0);"
                          @click="changePasswordType()"
                          class="input-group-text bg-transparent"
                        >
                          <i :class="iconStatus ? 'bx bx-hide' : 'bx bx-show'"></i>
                        </a>
                      </div>
                    </div>
                    <div class="modal-body col-md-6">
                      <label class="form-label">
                        Confirm Password:
                        <span style="color: red; font-size: 18px">*</span>
                      </label>
                      <div class="input-group" id="show_hide_password">
                        <input
                          :type="inputTypeConfirmPassword"
                          v-model="confirmPassword"
                          class="form-control border-end-0"
                          id="inputConfirmPassword"
                          placeholder="Enter confirm password"
                        />
                        <a
                          href="javascript:void(0);"
                          @click="changeConfirmPasswordType()"
                          class="input-group-text bg-transparent"
                        >
                          <i :class="iconFlag ? 'bx bx-hide' : 'bx bx-show'"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                      <i class="fadeIn animated bx bx-x"></i>Close
                    </button>
                    <button type="button" @click="addUser" class="btn btn-primary">
                      <i class="bx bxs-plus-square"></i>Add
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <!-- Add Modal -->
          <!-- Edit Role Modal -->
          <div
            class="modal fade"
            id="editModal"
            tabindex="-1"
            aria-labelledby="addModalLabel"
            aria-hidden="true"
          >
            <form @submit.prevent="updateUser">
              <div class="modal-dialog modal-lg">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="addModalLabel">Update User</h5>
                    <button
                      type="button"
                      class="btn-close"
                      id="editModalCloseButton"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="row ms-1">
                    <div class="modal-body col-md-6">
                      <label class="form-label">
                        First Name:
                        <span style="color: red; font-size: 18px">*</span>
                      </label>
                      <input class="form-control mb-1" type="text" v-model="singleUser.firstName" />
                    </div>
                    <div class="modal-body col-md-6">
                      <label class="form-label">
                        Last Name:
                        <span style="color: red; font-size: 18px">*</span>
                      </label>
                      <input class="form-control mb-1" type="text" v-model="singleUser.lastName" />
                    </div>
                  </div>
                  <div class="row ms-1">
                    <div class="modal-body col-md-6">
                      <label class="form-label">
                        Email:
                        <span style="color: red; font-size: 18px">*</span>
                      </label>
                      <input
                        class="form-control mb-1"
                        type="email"
                        v-model="singleUser.email"
                        aria-label="default input example"
                        disabled
                      />
                    </div>
                    <div class="modal-body col-md-6">
                      <label class="form-label">
                        Phone No:
                        <span style="color: red; font-size: 18px">*</span>
                      </label>
                      <input
                        class="form-control mb-1"
                        type="tel"
                        v-model="singleUser.phone"
                        aria-label="default input example"
                      />
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="submit" class="btn btn-primary">Update</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <!-- Edit Role Modal -->
        </div>
      </div>
      <h6 class="mb-0 text-uppercase">User List</h6>
      <hr />
      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <table id="example" class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>SL</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Created At</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(data, index) in dataList" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ data.firstName + ' ' + data.lastName }}</td>
                  <td>{{ data.email }}</td>
                  <td>{{ data.phone }}</td>
                  <td>{{ $filters.formatDate(data.createdAt) }}</td>
                  <td>
                    <div class="d-flex order-actions">
                      <a
                        href="javascript:void(0);"
                        v-on:click="editUser(data)"
                        data-bs-toggle="modal"
                        data-bs-target="#editModal"
                        class="edit-button"
                      >
                        <i class="bx bxs-edit text-white"></i>
                      </a>
                      <a
                        href="javascript:void(0);"
                        v-on:click="deleteUser(data.id)"
                        class="ms-3 delete-button"
                        :style="[ data.id == id ? { 'pointer-events': 'none', 'background-color': '#e56f7a' } : '']"
                      >
                        <i class="bx bxs-trash text-white"></i>
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th>SL</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Created At</th>
                  <th>Action</th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src='../js/approved-user.js'></script>


<style scoped>
@import "../css/user.css";

.edit-button {
  background-color: #17a00e;
}

.delete-button {
  background-color: #f41127;
}
</style>