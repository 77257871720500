export default {
    data() {
        return {
            url: this.$serverURL + this.$api.cms.pageTitleCMS,
            cmsData: {},
        }
    },
    async created() {
        document.title = "Transaction Bee - Site Page Title CMS";
        this.getData();

    },
    methods: {
        async getData() {
            let response = await this.getCmsData(this.url);
            if (response.statusCode == 200) {
                this.cmsData = (response.data.length > 0) ? response.data[0] : {};
            }
            else {
                this.cmsData = {};
            }
        },
        async updateCms() {
            try {
                let data = {
                    'signupPageTitle': this.cmsData.signupPageTitle,
                    'loginPageTitle': this.cmsData.loginPageTitle,
                    'homePageTitle': this.cmsData.homePageTitle,
                    'aboutPageTitle': this.cmsData.aboutPageTitle,
                    'pricingPageTitle': this.cmsData.pricingPageTitle,
                    'contactPageTitle': this.cmsData.contactPageTitle,
                    'careerPageTitle': this.cmsData.careerPageTitle,
                    'careerDetailsPageTitle': this.cmsData.careerDetailsPageTitle,
                    'faqPageTitle': this.cmsData.faqPageTitle,
                    'faqDetailsPageTitle': this.cmsData.faqDetailsPageTitle,
                    'partnerPageTitle': this.cmsData.partnerPageTitle,
                    'pressKitPageTitle': this.cmsData.pressKitPageTitle,
                    'termsPageTitle': this.cmsData.termsPageTitle,
                    'securityPageTitle': this.cmsData.securityPageTitle,
                    'privacyPageTitle': this.cmsData.privacyPageTitle,
                    'schedulePageTitle': this.cmsData.schedulePageTitle,
                };
                this.$swal.showLoading();
                let response = await this.createUpdateCMS(this.url, data);
                if (response.statusCode == 200 || response.statusCode == 201) {
                    
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
                await this.getData();
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            } finally {
                this.$swal.hideLoading();
            }
        }
    }
}