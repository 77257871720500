<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <div class="breadcrumb-title pe-3">All Page Title CMS</div>
                <div class="ps-3">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb mb-0 p-0">
                            <li class="breadcrumb-item">
                                <router-link to="/home"><i class="bx bx-home-alt"></i></router-link>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">All Page Title CMS</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">All Page Title CMS</h5>
                    <hr/>
                    <div class="form-body mt-4">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="border border-3 p-4 rounded">
                                    <div class="row mb-3">
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Signup Page Title:</label>
                                                <input v-model="cmsData.signupPageTitle" class="form-control" placeholder="Signup page title"/>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Login Page Title:</label>
                                                <input v-model="cmsData.loginPageTitle" class="form-control" placeholder="Login page title"/>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Home Page Title:</label>
                                                <input v-model="cmsData.homePageTitle" class="form-control" placeholder="Home page title"/>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">About Page Title:</label>
                                                <input v-model="cmsData.aboutPageTitle" class="form-control" placeholder="About page title"/>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Pricing Page Title:</label>
                                                <input v-model="cmsData.pricingPageTitle" class="form-control" placeholder="Pricing page title"/>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Contact Page Title:</label>
                                                <input v-model="cmsData.contactPageTitle" class="form-control" placeholder="Contact page title"/>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Career Page Title:</label>
                                                <input v-model="cmsData.careerPageTitle" class="form-control" placeholder="Career page title"/>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Career Details Page Title:</label>
                                                <input v-model="cmsData.careerDetailsPageTitle" class="form-control" placeholder="Career Details page title"/>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">FAQ Page Title:</label>
                                                <input v-model="cmsData.faqPageTitle" class="form-control" placeholder="FAQ page title"/>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">FAQ Details Page Title:</label>
                                                <input v-model="cmsData.faqDetailsPageTitle" class="form-control" placeholder="FAQ Details page title"/>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Partner Page Title:</label>
                                                <input v-model="cmsData.partnerPageTitle" class="form-control" placeholder="Partner page title"/>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Press Kit Page Title:</label>
                                                <input v-model="cmsData.pressKitPageTitle" class="form-control" placeholder="Press Kit page title"/>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Terms Page Title:</label>
                                                <input v-model="cmsData.termsPageTitle" class="form-control" placeholder="Terms page title"/>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Security Page Title:</label>
                                                <input v-model="cmsData.securityPageTitle" class="form-control" placeholder="Security page title"/>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Privacy Page Title:</label>
                                                <input v-model="cmsData.privacyPageTitle" class="form-control" placeholder="Privacy page title"/>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Schedule Page Title:</label>
                                                <input v-model="cmsData.schedulePageTitle" class="form-control" placeholder="Schedule page title"/>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="d-grid">
                                        <button v-on:click="updateCms()" type="button" class="btn btn-primary">Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="../js/site-page-title-cms.js"></script>