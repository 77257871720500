export default {
    data() {
        return {
            url: this.$serverURL + this.$api.home.marketYourBusiness,
            specificationKey: [],
            specificationValue: [],
            specificationArray: {},
            counter: 1,
            cmsData: {
                image: '',
                title: '',
                subTitle: '',
                description: '',
                buttonText: '',
                buttonLink: '',
            },
            previewImage: "",
        }
    },
    async created() {
        document.title = "Transaction Bee - Market Your Business CMS";
        this.getData();
    },
    methods: {
        async getData() {
            let response = await this.getCmsData(this.url);
            if (response.statusCode == 200) {
                this.cmsData = (response.data.length > 0) ? response.data[0] : {};
                if(Object.keys(this.cmsData).length > 0) {
                    this.counter = 0;
                    let bannerTextData = this.cmsData.features;
                    if (bannerTextData != null) {
                        let dataArray = JSON.parse(bannerTextData);
                        let index = 0;
                        for (const [key, value] of Object.entries(dataArray)) {
                            this.specificationKey[index] = key;
                            this.specificationValue[index] = value;
                            this.specificationArray[this.specificationKey[index]] = this.specificationValue[index];
                            index++;
                            this.counter = this.counter + 1;
                        }
                    }
                    else {
                        this.counter = 1;
                    }
                }
                else {
                    this.counter = 1;
                }
            }
            else {
                this.cmsData = {};
            }
        },
        addSpecification() {
            let index = this.counter - 1;
            if (!this.specificationKey[index]) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter features!"
                });
                return;
            }
            if (!this.specificationValue[index]) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter icon!"
                });
                return;
            }
            this.counter = this.counter + 1;
        },
        deleteSpecificationRow(index) {
            delete this.specificationArray[this.specificationKey[index]];
            this.specificationKey.splice(index, 1);
            this.specificationValue.splice(index, 1);
            this.counter = this.counter - 1;
        },
        handleUploadImage(imageKey, ref, event) {
            this.cmsData[imageKey] = event.target.files[0];
            let input = this.$refs[ref];
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewImage = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        async updateCms() {
            try {
                this.specificationArray = {};
                for (let i = 0; i < this.counter; i++) {
                    this.specificationArray[this.specificationKey[i]] = this.specificationValue[i];
                }
                const formData = new FormData();
                formData.append('image', this.cmsData.image);
                formData.append('title', this.cmsData.title);
                formData.append('subTitle', this.cmsData.subTitle);
                formData.append('description', this.cmsData.description);
                formData.append('buttonText', this.cmsData.buttonText);
                formData.append('buttonLink', this.cmsData.buttonLink);
                formData.append('features', JSON.stringify(this.specificationArray));
                this.$swal.showLoading();
                let response = await this.createUpdateCMS(this.url, formData);
                if (response.statusCode == 200 || response.statusCode == 201) {
                    this.previewImage = "";
                    this.$refs.image.value = "";
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
                await this.getData();
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            } finally {
                this.$swal.hideLoading();
            }
        }
    }
}