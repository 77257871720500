export default {
    name: "Approved Users",
    data() {
        return {
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            password: '',
            confirmPassword: '',
            inputTypePassword: "password",
            inputTypeConfirmPassword: "password",
            iconStatus: true,
            iconFlag: true,
            setURL: this.$serverURL + this.$api.user.userList,
            singleUser: {},
            dataList: [],
            status: "",
        }
    },
    async created() {
        document.title = "Transaction Bee - Approved User";
        await this.getUserList();
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
        let style = document.createElement('link');
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = '/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css';
        document.head.appendChild(style);
    },
    methods: {
        async getUserList() {
            const data = await this.getDataList(this.setURL);
            this.dataList = data.data.filter((el) => (el.status == "Approved"));
        },
        checkValidation: function () {
            if (!this.firstName) {
                this.$swal.fire({
                    icon: "info",
                    title: "Please enter first name!"
                });
                return false;
            }
            if (!this.lastName) {
                this.$swal.fire({
                    icon: "info",
                    title: "Please enter last name!"
                });
                return false;
            }
            if (!this.email) {
                this.$swal.fire({
                    icon: "info",
                    title: "Please enter email!"
                });
                return false;
            }
            if (!this.phone) {
                this.$swal.fire({
                    icon: "info",
                    title: "Please enter phone number!"
                });
                return false;
            }
            if (this.phone.length <= 9) {
                this.$swal.fire({
                    icon: "info",
                    title: "Phone number nust be at least 10 digits!"
                });
                return false;
            }
            if (!this.password) {
                this.$swal.fire({
                    icon: "info",
                    title: "Please enter password!"
                });
                return false;
            }
            if (!this.confirmPassword) {
                this.$swal.fire({
                    icon: "info",
                    title: "Please enter confirm password!"
                });
                return false;
            }
            if (this.password != this.confirmPassword) {
                this.$swal.fire({
                    icon: "info",
                    title: "Password and Confirm Password does not match!"
                });
                return false;
            }
            return true;
        },
        addUser: async function () {
            if (this.checkValidation()) {
                let data = {
                    firstName: this.firstName,
                    lastName: this.lastName,
                    email: this.email,
                    password: this.password,
                    phone: this.phone,
                };
                let url = this.$serverURL + this.$api.user.registrationURL;
                let response = await this.postData(url, data);
                if (response.statusCode == 201) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                    document.getElementById('addModalCloseButton').click();
                    this.firstName = '';
                    this.lastName = '';
                    this.email = '';
                    this.phone = '';
                    this.userType = 'admin';
                    this.password = '';
                    this.confirmPassword = '';
                    this.inputTypePassword = "password";
                    this.inputTypeConfirmPassword = "password";
                }
            }
        },
        editUser: function (data) {
            this.singleUser = data;
        },
        checkUpdateValidation: function () {
            if (!this.singleUser.firstName) {
                this.$swal.fire({
                    icon: "info",
                    title: "Please enter first name!"
                });
                return false;
            }
            if (!this.singleUser.lastName) {
                this.$swal.fire({
                    icon: "info",
                    title: "Please enter last name!"
                });
                return false;
            }
            if (!this.singleUser.email) {
                this.$swal.fire({
                    icon: "info",
                    title: "Please enter email!"
                });
                return false;
            }
            if (!this.singleUser.phone) {
                this.$swal.fire({
                    icon: "info",
                    title: "Please enter phone number!"
                });
                return false;
            }
            if (this.singleUser.phone.length <= 9) {
                this.$swal.fire({
                    icon: "info",
                    title: "Phone number nust be at least 10 digits!"
                });
                return false;
            }
            return true;
        },
        updateUser: async function () {
            if (this.checkUpdateValidation()) {
                let data = {
                    firstName: this.singleUser.firstName,
                    lastName: this.singleUser.lastName,
                    phone: this.singleUser.phone,
                    userType: this.singleUser.userType
                };
                let url = this.$serverURL + this.$api.user.singleUserURL + this.singleUser.id;
                let response = await this.updateData(url, data);
                if (response.statusCode == 200) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
                document.getElementById('editModalCloseButton').click();
                this.singleUser = {};
                await this.getUserList();
            }
        },
        changePasswordType: function () {
            if (this.inputTypePassword == "password") {
                this.inputTypePassword = "text";
                this.iconStatus = false;
            }
            else if (this.inputTypePassword == "text") {
                this.inputTypePassword = "password";
                this.iconStatus = true;
            }
        },
        changeConfirmPasswordType: function () {
            if (this.inputTypeConfirmPassword == "password") {
                this.inputTypeConfirmPassword = "text";
                this.iconFlag = false;
            }
            else if (this.inputTypeConfirmPassword == "text") {
                this.inputTypeConfirmPassword = "password";
                this.iconFlag = true;
            }
        },



        deleteUser: async function (id) {
            let url = this.$serverURL + this.$api.user.singleUserURL + id;
            this.$swal.showLoading();
            await this.deleteContent(url).then(async (response) => {
                if (response == true) {
                    await this.getUserList();
                }
            })
        }
    }
}