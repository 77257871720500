export default {
    name: "Benefits",
    data() {
        return {
            single: {
                id: "",
                reviewMessage: "",
                userName: "",
                userPhoto: "",
                icon: "",
                iconText: ""
            },
            preview: {
                image: "",
            },
            reviewMessage: "",
            userName: "",
            userPhoto: "",
            icon: "",
            iconText: "",
            setURL: this.$serverURL + this.$api.home.customerReviews,
            dataList: [],
        }
    },
    async created() {
        document.title = "Transaction Bee - Home Page Customer Reviews";
        const { data } = await this.getDataList(this.setURL);
        this.dataList = data;
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
        let style = document.createElement('link');
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = '/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css';
        document.head.appendChild(style);
    },
    methods: {
        addReview: async function () {
            if (!this.reviewMessage) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter review message!"
                });
            } else if (!this.userName) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please add reviewer name!"
                });
            } else if (!this.userPhoto) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please add reviewer photo!"
                });
            } else if (!this.icon) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please add icon class name!"
                });
            } else if (!this.iconText) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please add icon text!"
                });
            } else {
                const fromData = new FormData();
                fromData.append("reviewMessage", this.reviewMessage);
                fromData.append("userName", this.userName);
                fromData.append("userPhoto", this.userPhoto);
                fromData.append("icon", this.icon);
                fromData.append("iconText", this.iconText);
                const url = this.setURL;
                let response = await this.postData(url, fromData);
                if (response.statusCode == 201) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
                this.reviewMessage = '';
                this.userName = '';
                this.userPhoto = null;
                this.preview = {};
                this.$refs.imageAdd = null;
                this.icon = '';
                this.iconText = '';
                const { data } = await this.getDataList(this.setURL);
                this.dataList = data;
                document.getElementById("closeButtonAdd").click();
            }
        },
        editReview: async function (data) {
            this.single = { ...data };
        },
        updateReview: async function () {
            if (!this.single.reviewMessage) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter review message!"
                });
            } else if (!this.single.userName) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please add reviewer name!"
                });
            } else if (!this.single.userPhoto) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please add reviewer photo!"
                });
            } else if (!this.single.icon) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please add icon class name!"
                });
            } else if (!this.single.iconText) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please add icon text!"
                });
            } else {
                const fromData = new FormData();
                fromData.append("reviewMessage", this.single.reviewMessage);
                fromData.append("userName", this.single.userName);
                fromData.append("userPhoto", this.single.userPhoto);
                fromData.append("icon", this.single.icon);
                fromData.append("iconText", this.single.iconText);
                const url = this.setURL + '/' + this.single.id;
                let response = await this.updateData(url, fromData);
                if (response.statusCode == 200) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
                this.single = {};
                this.$refs.imageEdit = null;
                this.preview = {};
                const { data } = await this.getDataList(this.setURL);
                this.dataList = data;
                document.getElementById("closeButtonEdit").click();
            }
        },
        deleteReview: async function (id) {
            try {
                const url = this.setURL + '/' + id;
                await this.deleteContent(url);
                const { data } = await this.getDataList(this.setURL);
                this.dataList = data;
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            }
        },
        handleUploadImage(imageKey, previewImageKey, ref, event) {
            if (ref === "imageEdit") {
                this.single[imageKey] = event.target.files[0];
            } else {
                this.userPhoto = event.target.files[0];
            }
            let input = this.$refs[ref];
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader;
                reader.onload = e => {
                    this.preview[previewImageKey] = e.target.result;
                }
                reader.readAsDataURL(file[0]);
            }
        },
    }
}