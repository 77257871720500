export default {
    name: "Contact",
    data() {
        return {
            dataList: [],
            requestUrl: this.$serverURL + this.$api.contact.requestURL,
            message: "",
            filter: {
                startDate: "",
                endDate: ""
            }
        }
    },
    async created() {
        document.title = "Transaction Bee - Contact";
        await this.getData();
        this.loadScripts();
    },
    methods: {
        async getData() {
            try {
                const { data } = await this.getDataList(this.requestUrl);
                this.dataList = data;
            } catch (err) {
                console.log(err);
            }
        },
        async updateModalValue(message) {
            this.message = message;
        },
        async deleteData(id) {
            const url = this.requestUrl + '/' + id
            this.$swal.showLoading();
            await this.deleteContent(url).then(async (response) => {
                if (response == true) {
                    await this.getData();
                }
            })
        }
    }
}