<template>
  <div class="sidebar-wrapper" data-simplebar="true">
    <div class="sidebar-header">
      <div>
        <img
          src="../../../../public/core/assets/images/logo_full.svg"
          class="logo-icon"
          alt="logo icon"
        />
      </div>
      <!-- <div class="toggle-icon ms-auto">
                <i class="bx bx-arrow-to-left"></i>
      </div>-->
    </div>
    <ul class="metismenu" id="menu">
      <li>
        <a href="javascript:void(0)" @click="$router.push('/home')">
          <div class="parent-icon">
            <i class="fa fa-dashboard"></i>
          </div>
          <div class="menu-title">Dashboard</div>
        </a>
      </li>

      <li>
        <a href="javascript:void(0)" @click="$router.push('/contact')">
          <div class="parent-icon">
            <i class="bx bxs-contact"></i>
          </div>
          <div class="menu-title">Contact</div>
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" @click="$router.push('/subscription')">
          <div class="parent-icon">
            <i class="bx bxs-id-card"></i>
          </div>
          <div class="menu-title">Subscription</div>
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" @click="$router.push('/schedule')">
          <div class="parent-icon">
            <i class="bx bxs-calendar-event"></i>
          </div>
          <div class="menu-title">Schedule</div>
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" @click="$router.push('/office-address')">
          <div class="parent-icon">
            <i class="bx bxs-location-plus"></i>
          </div>
          <div class="menu-title">Office Address</div>
        </a>
      </li>
      <li>
        <a href="#" class="has-arrow">
          <div class="parent-icon">
            <i class="bx bxs-user-account"></i>
          </div>
          <div class="menu-title">User</div>
        </a>
        <ul>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/admin')">
              <i class="bx bx-right-arrow-alt"></i>Admins
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/approved-user')">
              <i class="bx bx-right-arrow-alt"></i>Approved Users
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/unapproved-user')">
              <i class="bx bx-right-arrow-alt"></i>Unapproved Users
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/rejected-user')">
              <i class="bx bx-right-arrow-alt"></i>Rejected Users
            </a>
          </li>
        </ul>
      </li>
      <li>
        <a href="#" class="has-arrow">
          <div class="parent-icon">
            <i class="bx bxs-info-circle"></i>
          </div>
          <div class="menu-title">About Us</div>
        </a>
        <ul>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/about-page-content')">
              <i class="bx bx-right-arrow-alt"></i>About Us Content
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/leaders-content')">
              <i class="bx bx-right-arrow-alt"></i>Leaders Content
            </a>
          </li>
        </ul>
      </li>
      <li>
        <a href="#" class="has-arrow">
          <div class="parent-icon">
            <i class="fa-solid fa-file-circle-question"></i>
          </div>
          <div class="menu-title">Faq</div>
        </a>
        <ul>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/faq-content')">
              <i class="bx bx-right-arrow-alt"></i>Faq Content
            </a>
          </li>
        </ul>
      </li>
      <li>
        <a href="#" class="has-arrow">
          <div class="parent-icon">
            <i class="fa-solid fa-person-walking-luggage"></i>
          </div>
          <div class="menu-title">Career</div>
        </a>
        <ul>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/career-team')">
              <i class="bx bx-right-arrow-alt"></i>Career Team
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/career-location')">
              <i class="bx bx-right-arrow-alt"></i>Career Location
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/career')">
              <i class="bx bx-right-arrow-alt"></i>Career Model
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/career-applicant')">
              <i class="bx bx-right-arrow-alt"></i>Career Applicant
            </a>
          </li>
        </ul>
      </li>
      <li>
        <a href="#" class="has-arrow">
          <div class="parent-icon">
            <i class="fa-solid fa-newspaper"></i>
          </div>
          <div class="menu-title">Press</div>
        </a>
        <ul>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/press-link')">
              <i class="bx bx-right-arrow-alt"></i>Press Link
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/press-coverage')">
              <i class="bx bx-right-arrow-alt"></i>Press Coverage
            </a>
          </li>
        </ul>
      </li>
      <li>
        <a href="#" class="has-arrow">
          <div class="parent-icon">
            <i class="fa-solid fa-handshake"></i>
          </div>
          <div class="menu-title">Partner</div>
        </a>
        <ul>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/accelerate-content')">
              <i class="bx bx-right-arrow-alt"></i>Accelerate Content
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/premium-partners-content')">
              <i class="bx bx-right-arrow-alt"></i>Premium Partners
            </a>
          </li>
        </ul>
      </li>
      <li>
        <a href="#" class="has-arrow">
          <div class="parent-icon">
            <i class="fa-solid fa-screwdriver-wrench"></i>
          </div>
          <div class="menu-title">CMS Setting</div>
        </a>
        <ul>
          <li>
            <a href="#" class="has-arrow">
              <div class="parent-icon">
                <i class="bx bx-home-alt"></i>
              </div>
              <div class="menu-title">Home</div>
            </a>
            <ul>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/home-page-hero-section-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Hero Section
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/home-page-brand-section-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Brand Section
                </a>
              </li>
              <li>
                <a
                  href="javascript:void(0)"
                  @click="$router.push('/home-page-market-business-cms')"
                >
                  <i class="bx bx-right-arrow-alt"></i>Market Your Business
                </a>
              </li>
              <li>
                <a
                  href="javascript:void(0)"
                  @click="$router.push('/home-page-improve-section-cms')"
                >
                  <i class="bx bx-right-arrow-alt"></i>Improve Section CMS
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/whats-next-section-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Whats Next Section CMS
                </a>
              </li>
              <li>
                <a
                  href="javascript:void(0)"
                  @click="$router.push('/home-page-benefits-and-advantages-section-cms')"
                >
                  <i class="bx bx-right-arrow-alt"></i>Benefits Section CMS
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/home-page-benefits')">
                  <i class="bx bx-right-arrow-alt"></i>Benefits Section Data
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/how-it-works-crud')">
                  <i class="bx bx-right-arrow-alt"></i>How It Works
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/how-it-works-cms')">
                  <i class="bx bx-right-arrow-alt"></i>How It Works CMS
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/customer-reviews-crud')">
                  <i class="bx bx-right-arrow-alt"></i>Customer Reviews
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/customer-reviews-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Customer Reviews CMS
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/application-integration')">
                  <i class="bx bx-right-arrow-alt"></i>Application Integration
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/application-integration-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Application Integration CMS
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/easy-payment-crud')">
                  <i class="bx bx-right-arrow-alt"></i>Payment Package
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/easy-payment-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Easy Payment CMS
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a href="#" class="has-arrow">
              <div class="parent-icon">
                <i class="bx bx-book-content"></i>
              </div>
              <div class="menu-title">Policies</div>
            </a>
            <ul>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/privacy-policy-page-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Privacy Policy Page
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/security-policy-page-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Security Policy Page
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a href="#" class="has-arrow">
              <div class="parent-icon">
                <i class="bx bx-user-pin"></i>
              </div>
              <div class="menu-title">Auth</div>
            </a>
            <ul>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/signup-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Sign Up Page Cms
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/signin-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Sign In Page Cms
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/auth-common-data')">
                  <i class="bx bx-right-arrow-alt"></i>Auth Common Data
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a href="#" class="has-arrow">
              <div class="parent-icon">
                <i class="bx bx-message-alt-dots"></i>
              </div>
              <div class="menu-title">Contact Us</div>
            </a>
            <ul>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/contact-us-page-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Page Cms
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/contact-us-designations')">
                  <i class="bx bx-right-arrow-alt"></i>Designations
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/contact-us-page-organization')">
                  <i class="bx bx-right-arrow-alt"></i>Organization Size
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a href="#" class="has-arrow">
              <div class="parent-icon">
                <i class="bx bx-list-check"></i>
              </div>
              <div class="menu-title">Schedule</div>
            </a>
            <ul>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/schedule-page-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Schedule Page CMS
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/schedule-industry')">
                  <i class="bx bx-right-arrow-alt"></i>Schedule Industry
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/schedule-transaction-limit')">
                  <i class="bx bx-right-arrow-alt"></i>Transaction Limit
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/schedule-role')">
                  <i class="bx bx-right-arrow-alt"></i>Schedule Role
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a href="#" class="has-arrow">
              <div class="parent-icon">
                <i class="fa-solid fa-person-walking-luggage"></i>
              </div>
              <div class="menu-title">Career</div>
            </a>
            <ul>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/career-page-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Page CMS
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/career-details-page-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Details Page CMS
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/color-code-transaction-status')">
              <i class="bx bx-right-arrow-alt"></i>Color Code Transaction Status CMS
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/site-page-title-cms')">
              <i class="bx bx-right-arrow-alt"></i>Site Page Title CMS
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/site-page-meta-cms')">
              <i class="bx bx-right-arrow-alt"></i>Site Page Meta CMS
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/about-us-page-cms')">
              <i class="bx bx-right-arrow-alt"></i>About Us Page CMS
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/faq-page-cms')">
              <i class="bx bx-right-arrow-alt"></i>Faq Page CMS
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/terms-page-cms')">
              <i class="bx bx-right-arrow-alt"></i>Terms Page CMS
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/press-kit-page-cms')">
              <i class="bx bx-right-arrow-alt"></i>Press Kit Page CMS
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/press-about-content-cms')">
              <i class="bx bx-right-arrow-alt"></i>Press About Content CMS
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/partner-page-cms')">
              <i class="bx bx-right-arrow-alt"></i>Partner Page CMS
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/header-footer-cms')">
              <i class="bx bx-right-arrow-alt"></i>Header Footer CMS
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/newsletter-cms')">
              <i class="bx bx-right-arrow-alt"></i>Newsletter CMS
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/social-links')">
              <i class="bx bx-right-arrow-alt"></i>Social Links
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>