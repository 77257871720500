import { authHeader } from '../../../auth';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
    name: "EditCareer",
    data() {
        return {
            editData: [],
            title: '',
            team: '',
            locationType: '',
            address: '',
            description: '',
            salary: '',
            editor: ClassicEditor,
            careerTeams: [],
            careerLocations: [],
        }
    },
    async created() {
        document.title = "Transaction Bee - Edit Career Model";
        await this.getCareerTeams();
        await this.getCareerLocations();
    },
    mounted() {
        if (this.$route.params.id != undefined) {
            this.careerId = this.$route.params.id;
        }
        this.getCareerData(this.careerId);
    },
    methods: {
        getCareerTeams: async function () {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.career.careerTeamURL,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.careerTeams = response.data.data;
                }
            }).catch(error => {
                console.log(error);
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },
        getCareerLocations: async function () {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.career.careerLocationURL,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.careerLocations = response.data.data;
                }
            }).catch(error => {
                console.log(error);
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },
        getCareerData: async function (id) {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.career.careerModelURL + '/' + id,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.editData = response.data.data[0];
                    console.log("Career data: ", this.editData)
                    this.title = this.editData.title;
                    this.team = this.editData.team;
                    this.locationType = this.editData.locationType;
                    this.address = this.editData.address;
                    this.description = this.editData.description;
                    this.salary = this.editData.salary;
                }
            }).catch(error => {
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
        validate: function () {
            if (!this.locationType) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter location type!"
                });
                return false;
            }
            if (!this.team) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter team!"
                });
                return false;
            }
            if (!this.title) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter title!"
                });
                return false;
            }
            if (!this.address) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter address!"
                });
                return false;
            }
            if (!this.description) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter description!"
                });
                return false;
            }
            if (!this.salary) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter salary!"
                });
                return false;
            }
            return true;
        },
        update: async function () {
            if (this.validate() == true) {
                let token = localStorage.getItem('token');
                const formData = {
                    title: this.title,
                    team: this.team,
                    locationType: this.locationType,
                    address: this.address,
                    description: this.description,
                    salary: this.salary,
                }
                let config = {
                    method: "PATCH",
                    url: this.$serverURL + this.$api.career.careerModelURL + '/' + this.careerId,
                    data: formData,
                    headers: {
                        "Authorization": "Bearer " + token
                    }
                };
                await this.$axios(config).then((response) => {
                    if (response.status == 200) {
                        this.$swal.fire({
                            toast: true,
                            position: 'top-end',
                            text: response.data.message,
                            icon: "success",
                            showConfirmButton: false,
                            timer: 3000,
                            animation: false,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                            }
                        });
                    }
                    this.title = '';
                    this.team = ''
                    this.locationType = '';
                    this.address = '';
                    this.description = '';
                    this.salary = '';
                    this.$router.push("/career");
                }).catch(error => {
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            }
        }
    }
}