export default {
    data() {
        return {
            url: this.$serverURL + this.$api.cms.termsPageCms,
            cmsData: {},
            preview: {
                image1: '',
            },
        }
    },
    async created() {
        document.title = "Transaction Bee - Terms Page CMS";
        this.getData();

    },
    methods: {
        async getData() {
            let response = await this.getCmsData(this.url);
            if (response.statusCode == 200) {
                this.cmsData = (response.data.length > 0) ? response.data[0] : {};
            }
            else {
                this.cmsData = {
                    pageTitle:'',
                    termsofUseIcon:'',
                    termsofUseTitle:'',
                    file:'',
                    getApplicationTitle:'',
                    getApplicationLink:'',
                };
            }
        },
        handleUploadImage(imageKey, previewKey = '', ref, event) {
            this.cmsData[imageKey] = event.target.files[0];
            if (previewKey) {
                let input = this.$refs[ref];
                let file = input.files;
                if (file && file[0]) {
                    let reader = new FileReader
                    reader.onload = e => {
                        this.preview[previewKey] = e.target.result
                    }
                    reader.readAsDataURL(file[0])
                }
            }

        },
        async updateCms() {
            try {
                const formData = new FormData();
                formData.append('pageTitle', this.cmsData.pageTitle);
                formData.append('termsofUseIcon', this.cmsData.termsofUseIcon);
                formData.append('termsofUseTitle', this.cmsData.termsofUseTitle);
                formData.append('file', this.cmsData.file);
                formData.append('getApplicationTitle', this.cmsData.getApplicationTitle);
                formData.append('getApplicationLink', this.cmsData.getApplicationLink);
                this.$swal.showLoading();
                let response = await this.createUpdateCMS(this.url, formData);
                if (response.statusCode == 200 || response.statusCode == 201) {
                    this.preview.image1 = "";
                    this.$refs.icon.value = "";
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
                await this.getData();
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            } finally {
                this.$swal.hideLoading();
            }
        }
    }
}