export default {
    name: "CareerTeam",
    data()
    {
        return {
            single: {},
            name: "",
            setURL: this.$serverURL + this.$api.career.careerTeamURL,
            dataList: []
        }
    },
    async created()
    {
        document.title = "Transaction Bee | Career Team";
        const data = await this.getDataList(this.setURL);
        this.dataList = data.data;
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script =>
        {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
        let style = document.createElement('link');
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = '/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css';
        document.head.appendChild(style);
    },
    methods: {
        addEntity: async function () {
            if (!this.name) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter team name!"
                });
            }
            else {
                let data = {
                    name: this.name
                };
                let response = await this.postData(this.setURL, data);
                if (response.statusCode == 201) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
                this.name = '';
                let res = await this.getDataList(this.setURL);
                this.dataList = res.data;
                document.getElementById("closeButton").click();
            }
        },
        editEntity: async function (data) {
            this.single = data;
        },
        updateEntity: async function () {
            if (!this.single.name) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter team name!"
                });
            }
            else {
                let data = {
                    name: this.single.name
                };
                let url = this.setURL + '/' + this.single.id;
                let response = await this.updateData(url, data);
                if (response.statusCode == 200) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) =>
                        {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
                this.single = {};
                let res = await this.getDataList(this.setURL);
                this.dataList = res.data;
                document.getElementById("buttonClose").click();
            }
        },
        deleteEntity: async function (id) {
            let url = this.setURL + '/' + id;
            this.$swal.showLoading();
            await this.deleteContent(url).then(async (response) => {
                if (response == true) {
                    let teamData = await this.getDataList(this.setURL);
                    this.dataList = teamData.data;
                }
            })
        }
    }
}