<template>
  <div class="page-wrapper">
    <loading :active="isLoading" :is-full-page="fullPage" :loader="loader" />
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div class="breadcrumb-title pe-3">Leaders Page Content</div>
        <div class="ps-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
              <li class="breadcrumb-item">
                <router-link to="/home">
                  <i class="bx bx-home-alt"></i>
                </router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">Leaders Page Content</li>
            </ol>
          </nav>
        </div>
        <div class="ms-auto">
          <div class="btn-group">
            <button
              type="button"
              class="btn btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#addModal"
            >
              <i class="bx bxs-plus-square"></i>Add New Leader
            </button>
          </div>
        </div>
      </div>
      <h6 class="mb-0 text-uppercase">Leaders List</h6>
      <hr />
      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <table id="example" class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>Sl</th>
                  <th>Name</th>
                  <th>Image</th>
                  <th>Designation</th>
                  <th>Created At</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(data, index) in dataList" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ data.name }}</td>
                  <td>
                    <img v-if="data.image" :src="data.image" height="100" />
                    <img v-else :src="data.image" height="100" />
                  </td>
                  <td>{{ data.designation }}</td>
                  <td>{{ $filters.formatDate(data.createdAt) }}</td>
                  <td>
                    <div class="d-flex order-actions">
                      <a
                        href="javascript:void(0);"
                        data-bs-toggle="modal"
                        data-bs-target="#editModal"
                        v-on:click="editData(data)"
                        class="edit-button m-2"
                      >
                        <i class="bx bxs-edit"></i>
                      </a>
                      <a
                        href="javascript:void(0);"
                        v-on:click="deleteData(data.id)"
                        class="delete-button m-2"
                      >
                        <i class="bx bxs-trash"></i>
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th>Sl</th>
                  <th>Name</th>
                  <th>Image</th>
                  <th>Designation</th>
                  <th>Created At</th>
                  <th>Action</th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="addModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Add Content</h5>
            <button
              type="button"
              class="btn-close"
              id="add-close-btn"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <form @submit.prevent="createContent">
            <div class="modal-body">
              <div class="row">
                <div class="col-lg-12">
                  <div class="mb-3">
                    <label for="exampleFormControlInput1" class="form-label">Name:</label>
                    <input
                      type="text"
                      v-model="formData.name"
                      class="form-control"
                    />
                  </div>
                  <div class="mb-3">
                    <label for="exampleFormControlInput1" class="form-label">Designation:</label>
                    <input
                      type="text"
                      v-model="formData.designation"
                      class="form-control"
                    />
                  </div>
                </div>
                
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div class="col-12 mb-3">
                    <label class="form-label">
                      Image:[Preferred Image Size: 130X130, Max Upload Limit:
                      1MB]
                    </label>
                    <input
                      type="file"
                      class="form-control"
                      id="inputPageHeaderTitle"
                      placeholder="Enter page title"
                      ref="createImage"
                      accept="image/*, .svg"
                      @change="handleUploadImage('createImage', $event)"
                    />
                  </div>
                  <div class="mb-3">
                    <img
                      style="position: relative;"
                      v-if="previewImage"
                      :src="previewImage"
                      height="100"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="onCloseModal('createImage')">Close</button>
              <button type="submit" class="btn btn-primary">Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="editModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Edit Content</h5>
            <button
              type="button"
              class="btn-close"
              id="edit-close-btn"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <form @submit.prevent="update">
            <div class="modal-body">
              <div class="row">
                <div class="col-lg-12">
                  <div class="mb-3">
                    <label class="form-label">Name:</label>
                    <input
                      type="text"
                      v-model="singleData.name"
                      class="form-control"
                    />
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Designation:</label>
                    <input
                      type="text"
                      v-model="singleData.designation"
                      class="form-control"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div class="col-12 mb-3">
                    <label class="form-label">
                      Image:[Preferred Image Size: 130X130, Max Upload Limit:
                      1MB]
                    </label>
                    <input
                      type="file"
                      class="form-control"
                      id="inputPageHeaderTitle"
                      placeholder="Enter page title"
                      ref="editImage"
                      accept="image/*, .svg"
                      @change="handleUploadImage('editImage', $event)"
                    />
                  </div>
                  <div class="mb-3">
                    <img
                      style="position: relative;"
                      v-if="previewImage"
                      :src="previewImage"
                      height="100"
                    />
                    <img
                      style="position: relative;"
                      v-else-if="singleData.image"
                      :src="singleData.image"
                      height="100"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="onCloseModal('editImage')">Close</button>
              <button type="submit" class="btn btn-primary">Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
  
<style scoped>
@import "/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css";

.edit-button {
  background-color: #17a00e;
}

.delete-button {
  background-color: #f41127;
}
</style>

<script src="../js/leaders-content.js"></script>