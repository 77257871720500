export default {
    name: "CareerApplicant",
    data() {
        return {
            dataList: [],
            setURL: this.$serverURL + this.$api.career.careerApplicantURL,
            reason: ""
        }
    },
    async created() {
        document.title = "Transaction Bee - Career";
        const { data } = await this.getDataList(this.setURL);
        this.dataList = data;
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
        let style = document.createElement('link');
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = '/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css';
        document.head.appendChild(style);
    },
    methods: {
        async updateModalValue(reason) {
            this.reason = reason;
        },
        deleteData: async function (id) {
            try {
                const url = this.setURL + '/' + id
                await this.deleteContent(url);
                const { data } = await this.getDataList(this.setURL);
                this.dataList = data;
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            }
        }

    }
}